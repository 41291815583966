import React, { useState } from "react";
import 'moment/locale/es'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Select from 'react-select';


const CREATE_USUARIO = gql`
mutation CreateUsuarioMutation($usuarioData:UsuariosInput!){
  createUsuario(
    usuarioData: $usuarioData
  )
  {
  usuario{
    id
    nombre
  }  
  }
}
`;

export const GET_DATA = gql`
query {
  viewer {
    allRoles{
      edges{
        node{
          id
          name
        }
        
      }
    }
    allTipoCategorias {
      edges {
        node {
          id
          nombre
          createdAt
          updatedAt
        }
      }
    }
  }
}
`;

const estatusOptions = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
];

function UsuariosCreateComponent() {
  const history = useHistory();

  const [selectedEstatus, setSelectedEstatus] = useState("");
  const [selectedRole, setRole] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const [sexo, setSexo] = useState('M');
  const [selectedTipoCategoria, setTipoCategoria] = useState(null);
  const [tipoCategoriaOptions, setTipoCategoriaOptions] = useState([]);

  const { loading: requsitoLoading, error: usuarioError, data: usuarioData } = useQuery(GET_DATA, {
    
    onCompleted(data){
      
      const roles = [...data.viewer.allRoles.edges].map(o => 
        ({ value: o.node.id, label: o.node.name })
      )
      setRoleOptions(roles);

      const tipoCategorias = [...data.viewer.allTipoCategorias.edges].map(o => 
        ({ value: o.node.id, label: o.node.nombre })
      )
      setTipoCategoriaOptions(tipoCategorias);

    }
  });

  const [createUsuario, { data, loading, error, reset }] = useMutation(CREATE_USUARIO,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted(){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Usuario añadido`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });

        history.push({
          pathname:  `/catalogos/usuarios`,
          state: {
            response: 'succes' 
          } 
        });
        
      }
      
    }
    );

  let codigo, usuario, clave, nombre, paterno, materno, email, color;

  function handleChangeEstatus(selectedOption) {  
    setSelectedEstatus(selectedOption)
  }

  const handleChangeSexo = (event) => {
    setSexo(event.target.value)
  }

  function handleChangeRole(selectedOption) {
    setRole(selectedOption)
  }

  function handleTipoCategoria(selectedOption) {  
    setTipoCategoria(selectedOption)
  }
    

  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Editar Usuarios</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">Usuarios
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabindex="0">
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabindex="-1"></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div class="card-content">


          <h4 class="card-title">Catálogo de Usuarios</h4>

          
                                                      <form onSubmit={e => {
                                                              e.preventDefault();
                                                              createUsuario({ variables: { usuarioData : { usuario: usuario.value, clave : clave.value,
                                                                nombre: nombre.value, paterno: paterno.value, materno: materno.value,
                                                                sexo: sexo,
                                                                email: email.value,
                                                                roles: selectedRole ? [...selectedRole].map(o => o.value) : [],
                                                                estatus: selectedEstatus ? selectedEstatus.value : '', color : color.value,
                                                                categorias: [...selectedTipoCategoria].map(o => o.value) }} });
                                                              
                                                          }}>
                                                                                            <div className="input-field col s6">
                                                      <i className="material-icons prefix">account_circle</i>
                                                      <input type="text" className="form-control" name="usuario" ref={node => {
                                                        usuario = node;
                                                      }} required />
                                                      <label className="active" for="usuario">Usuario</label>
                                                    </div>
                                                    <div className="row">
                                                      <div className="input-field col s6">
                                                        <i className="material-icons prefix">lock_outline</i>
                                                        <input id="clave" name="clave" ref={node => {
                                                          clave = node;
                                                        }} type="password" required/>
                                                        <label for="clave" className="active">Contraseña</label>
                                                      </div>
                                                    </div>
                                                    <div className="row">
                                                      <div className="input-field col s4">
                                                        <i className="material-icons prefix">portrait</i>
                                                        <input id="nombre" name="nombre" ref={node => {
                                                          nombre = node;
                                                        }}
                                                          type="text" />
                                                        <label className="active" for="nombre">Nombre</label>
                                                      </div>
                                                      <div className="input-field col s4">
                                                        <i className="material-icons prefix">portrait</i>
                                                        <input id="paterno" name="paterno" ref={node => {
                                                          paterno = node;
                                                        }}
                                                          type="text" required />
                                                        <label className="active" for="paterno">Paterno</label>
                                                      </div>

                                                      <div className="input-field col s4">
                                                        <i className="material-icons prefix">portrait</i>
                                                        <input id="materno" name="materno" ref={node => {
                                                          materno = node;
                                                        }}
                                                          type="text"  required/>


                                                        <label className="active" for="materno">Materno</label>
                                                      </div>
                                                    </div>
                                                    <div className="col s6">

                                                      <p>Sexo</p>
                                                      <p>
                                                        <label>
                                                          <input class="validate"
                                                            required name="gender0"
                                                            type="radio"
                                                            value="M"
                                                            checked={sexo === 'M'}
                                                            onChange={handleChangeSexo} />
                                                          <span>Masculino</span>
                                                        </label>
                                                      </p>

                                                      <label>
                                                        <input class="validate"
                                                          required name="gender0"
                                                          type="radio"
                                                          value="F"
                                                          checked={sexo === 'F'}
                                                          onChange={handleChangeSexo} />
                                                        <span>Femenino</span>
                                                      </label>
                                                      <div class="input-field">
                                                      </div>
                                                    </div>


                                                    <div className="input-field col s6">
                                                      <p>Estatus</p>
                                                      <Select
                                                        value={selectedEstatus}
                                                        onChange={handleChangeEstatus}
                                                        options={estatusOptions}
                                                        classNamePrefix="Selecciona una opción"
                                                        name="estatus"
                                                      />
                                                    </div>

                                                    <div className="row">
                                                      <div className="input-field col s6">
                                                        <p>Roles</p>
                                                        <Select
                                                          isMulti
                                                          name="roles"
                                                          value={selectedRole}
                                                          onChange={handleChangeRole}
                                                          options={roleOptions}
                                                          classNamePrefix="Selecciona una opción"
                                                          isSearchable={true}
                                                        />



                                                      </div>

                                                      <div className="input-field col s6">
                                                        <i className="material-icons prefix">email</i>
                                                        <input id="email" name="email" ref={node => {
                                                          email = node;
                                                        }}
                                                          type="email" />
                                                        <label className="active" for="email">Email</label>
                                                      </div>
                                                    </div>

                                                    <div className="row">
                                                    <div class="input-field col s12">
                                                            <p>Categoria</p>
                                                            <Select
                                                              isMulti
                                                              name="categorias"
                                                              value={selectedTipoCategoria}
                                                              onChange={handleTipoCategoria}
                                                              options={tipoCategoriaOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>
                                                          </div>

                                                    <div className="row">
                                                      <div className="input-field col s6">
                                                        <div id="date-picker" className="card card-tabs">
                                                          <div className="card-content">
                                                            <div className="card-title">
                                                              <div className="row">
                                                                <h4 className="card-title">Fecha Baja</h4>

                                                              </div>
                                                            </div>
                                                            <div id="view-date-picker">
                                                              <label for="birthdate" className="">Insertar Fecha Baja</label>
                                                              <input type="text" className="datepicker" id="birthdate" />
                                                            </div>
                                                            <div id="html-date-picker">

                                                            </div>
                                                            <div id="js-date-picker">

                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="input-field col s6">
                                                        <div id="date-picker" className="card card-tabs">
                                                          <div className="card-content">
                                                            <div className="card-title">
                                                              <div className="row">
                                                                <h4 className="card-title">Fecha Baja</h4>

                                                              </div>
                                                            </div>
                                                            <div id="view-date-picker">
                                                              <label for="birthdate" className="">Insertar Fecha Baja</label>
                                                              <input type="text" className="datepicker" id="birthdate" />
                                                            </div>
                                                            <div id="html-date-picker">

                                                            </div>
                                                            <div id="js-date-picker">

                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="color" className="form-control" name="color" ref={node => {
                                                              color = node;
                                                          }} defaultValue={usuarioData.viewer.usuario.color} required/>
                                                          <label class="active" for="color">Color</label>
                                                          </div>

                                                      <div className="row">
                                                        <div className="input-field col s12">
                                                          {!loading ?
                                                            <button className="btn cyan waves-effect waves-light right"
                                                              type="submit" name="action">Enviar
                                                              <i className="material-icons right">send</i>
                                                            </button>

                                                            :

                                                            <button className="btn btn-customizer cyan waves-effect waves-light right"
                                                              type="submit" name="action">Procesando
                                                              <i className="material-icons right">sync</i>
                                                            </button>

                                                          }
                                                        </div>
                                                      </div>
                                                    </div>
                                                        

                                                        
                                                      </form>
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>
                            </div>
      </div>
  );
}

UsuariosCreateComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default UsuariosCreateComponent;
