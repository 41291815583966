import React, { useState } from "react";
import Axios from "axios";
import { gql, useQuery } from '@apollo/client';
import LoadingComponent from "../../loading.component";
import Select from 'react-select';

export const GET_USUARIOS = gql`
query {
  viewer {
    allUsuarios {
      edges {
        node {
          id
          nombre
          paterno
          materno
          email
          usuario
          username
          createdAt
          roles{
            edges{
              node{
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
`;


export default function ReporteActividadesVencidas() {

  const [selectedUsuario, setUsuario] = useState(null);
  const [usuarioOptions, setUsuarioOptions] = useState([]);

  const { client, loading, data, error, refetch} = useQuery(GET_USUARIOS, 
    { fetchPolicy: "network-only",
    onCompleted(data){
      
      

      const usuarios = [...data.viewer.allUsuarios.edges].map(o => 
        ({ value: o.node.username, label: `${o.node.nombre} ${o.node.paterno} ${o.node.materno}` })
      )
      console.log(usuarios);
      setUsuarioOptions(usuarios); 
      
      
    },    
    onError(error){
      //reset();
      console.log(error);

    }
  });

  function handleChangeUsuario(selectedOption) {  
    setUsuario(selectedOption)
    console.log(selectedOption);
  }

  const [year, setYear] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [movieDetails, setDetails] = useState([]);
  const [show, setHide] = useState(false);
  const [loadingPdf, setLoading] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const openPDF = (url) => {
    window.open(url, '_blank');
};

  const fetchMovie = async e => {
    setYear(e.target.value);
    setHide(false);
    setLoading(true);
    try {
      let res = await Axios.get(
        `https://apiwhipe.creasis.mx/actividadesvencidas/pdf`
      );
      setPdfFile(res.data);
      //console.log(selectedUsuario.value);
      setDetails(res);
      setLoading(false);
      setHide(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  if (error) return `Error! ${error.message}`;

  return (
          <>
          <div class="col s12">
          <div class="container">
            
<section class="invoice-list-wrapper section">
<div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
  
  
{loading ? 
                                                <LoadingComponent /> 
                                                :
  <div class="responsive-table">
    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer">
      <div class="top display-flex  mb-2"><div class="action-filters">
        <div id="DataTables_Table_0_filter" class="dataTables_filter">
                      
          </div></div><div class="actions action-btns display-flex align-items-center"><div class="invoice-filter-action mr-3">
    <a href="#" onClick={fetchMovie} class="btn waves-light invoice-export border-round z-depth-4">
      <i class="material-icons">picture_as_pdf</i>
      <span class="hide-on-small-only">Exportar a PDF</span>
    </a>
  </div>
  
  </div></div><div class="clear"></div>

  <div class="col s12">
        <div class="container">
          
          <div class="card">
            <div class="card-content">
              <h4 class="card-title">Reporte de actividades vencidas</h4>
              <div class="row">
                <div class="row">
                {loadingPdf && (
                  <>
                  <LoadingComponent /> 
                  </>
                )
                }

                {show && (
                <>

                <object style={{width:100 +'%', height: 800}} data="https://whip.creasis.mx/reports/whip_actividades_vencidas.pdf" type="application/pdf">
                    <iframe style={{width:100 +'%', height: 800}} src="https://docs.google.com/viewer?url=https://whip.creasis.mx/reports/whip_actividades_vencidas.pdf&embedded=true"></iframe>
                </object>

                
                
                </>

                    
                  )}
                  
                </div>
              </div>
            </div>
          </div>

          
          
        </div>
        <div class="content-overlay"></div>
      </div>

  
  
    
    </div>
  </div>
}
</section>
          </div>
          <div class="content-overlay"></div>
        </div>
    
    </>
  );
}
