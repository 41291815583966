import React, { Component, useState } from "react";
import 'moment/locale/es'
import LoadingComponent from "../../loading.component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Select from 'react-select';


const GET_ACTO = gql`
query acto($actoId: String) {
  viewer {
    acto(actoId: $actoId) {
      id
      codigo
      nombre
      requisitos{
        edges{
          node{
            codigo
            nombre
          }
        }
      }
    }
    allRequisitos {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
  }
}
`;
const UPDATE_ACTO = gql`
mutation UpdateActoMutation($actoData:ActosInput!){
  updateActo(
    actoData: $actoData
  )
  {
  acto{
    id
    nombre
  }  
  }
}
`;


function ActosEditComponent(acto) {

  let actoId = acto.match.params.id;
  const [selectedRequisito, setRequisito] = useState(null);
  const [requisitoOptions, setRequsitoOptions] = useState([]);

  const history = useHistory();
  const { loading: requsitoLoading, error: actoError, data: actoData } = useQuery(GET_ACTO, {
    variables: { actoId },
    onCompleted(data){

      const defaultRequisitos = [...data.viewer.acto.requisitos.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )

      setRequisito(defaultRequisitos)
      
      const requisitos = [...data.viewer.allRequisitos.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )
      setRequsitoOptions(requisitos);
    }
  }); 

    

  const [updateActo, { data, loading, error, reset }] = useMutation(UPDATE_ACTO,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted(){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Acto actualizado`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });

        history.push({
          pathname:  `/catalogos/actos`,
          state: {
            response: 'success' 
          } 
        });
        
      }
      
    }
    );
  let codigo, nombre;

  function handleChangeRequisito(selectedOption) {  
    setRequisito(selectedOption)
  }

  //
  //if (requsitoLoading) return 'Loading...';   
  if (actoError) return `Error! ${actoError.message}`;
  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Editar Actos</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">Actos
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabindex="0">
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabindex="-1"></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div class="card-content">


          <h4 class="card-title">Catálogo de Actos</h4>
                                                {requsitoLoading ? 
                                                <LoadingComponent /> 
                                                :

          
                                                      <form onSubmit={e => {
                                                              e.preventDefault();
                                                              updateActo({ variables: { actoData : { id: actoData.viewer.acto.id, codigo: codigo.value,
                                                                 nombre: nombre.value, requisitos: selectedRequisito ? [...selectedRequisito].map(o => o.value)  : [] }} });
                                                              
                                                          }}>
                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">code</i>
                                                          <input disabled type="text" className="form-control" name="codigo" ref={node => {
                                                              codigo = node;
                                                          }} defaultValue={actoData.viewer.acto.codigo} />

                                                          
                                                          <label class="active" for="codigo">Código</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="nombre" ref={node => {
                                                              nombre = node;
                                                          }} defaultValue={actoData.viewer.acto.nombre} required/>
                                                          <label class="active" for="nombre">Acto</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <p>Requisitos</p>
                                                            <Select
                                                              isMulti
                                                              name="requisitos"
                                                              value={selectedRequisito}
                                                              onChange={handleChangeRequisito}
                                                              options={requisitoOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>


                                                        

                                                        <div class="row">
                                                            <div class="row">
                                                            <div class="input-field col s12">
                                                            {!loading ? 
                                                              <button class="btn cyan waves-effect waves-light right"
                                                              type="submit" name="action">Enviar
                                                                <i class="material-icons right">send</i>
                                                              </button>
                                                            
                                                              :

                                                              <button class="btn btn-customizer cyan waves-effect waves-light right"
                                                              type="submit" name="action">Procesando
                                                                <i class="material-icons right">sync</i>
                                                              </button>
                                                            
                                                            }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                      }
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>
                            </div>
      </div>
  );
}

ActosEditComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default ActosEditComponent;
