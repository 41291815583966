import React, { useState } from "react";
import 'moment/locale/es'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { PDFDownloadLink, PDFViewer, Document, Page } from '@react-pdf/renderer';

const CREATE_REQUISITO = gql`
mutation uploadFileMutation($file: Upload!) {
  uploadFile(file: $file) {
    success
  }
}
`;

export const GET_REQUISITOS = gql`
query {
  viewer {
    allRequisitos {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
  }
}
`;

const SINGLE_UPLOAD = gql`
  mutation($file: Upload!) {
    uploadFile(file: $file) {
      succes
      filename
      mimetype
      encoding
      url
    }
  }
`;

const SINGLE_UPLOAD_MUTATION = gql`
  mutation uploadFileMutation($file: Upload!) {
    uploadFile(file: $file) {
      success
      fileName
    }
  }
`;

function UploadCreateComponent() {
  const history = useHistory();
  //const [file, setFile] = useState(null);
  const [mutate, { loading, error }] = useMutation(SINGLE_UPLOAD);
  const [file, setFile] = useState(null);
  const [msg, setMsg] = useState('');
  const [image, setImage] = useState(null);
  const [uploadRequest, {data:DataUpload, loading : LoadingUpload, error: ErrorUpload }] = useMutation(
    SINGLE_UPLOAD_MUTATION,{
      onCompleted(data){
        const fileName = `/app-assets/uploads/${data.uploadFile.fileName}`;
        console.log(data);
        setImage(fileName);

      }
    }
  );

  const uploadFile = async () => {
    setMsg('');
    if (!file) return;
    try {
      const res = await uploadRequest({
        variables: { file }
        //,refetchQueries: [{ query: GET_PHOTOS_QUERY }],
      });
      if (res.data) {
        setMsg('File upload!');
        //setFile(null);
        setTimeout(() => setMsg(''), 3000);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [createRequisito, { data, loading : LoadigMutate, error: ErrorMutate, reset }] = useMutation(CREATE_REQUISITO,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted(){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Upload Success añadido`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });

        
        
      }
      
    }
    );
  let codigo, nombre;

  function uploadFiles(e) {
    const files = e.target.files[0];
    console.log(e.target.files);
    if (files != null) {
      const data = new FormData();
      data.append('file_from_react', files);
      fetch('http://localhost:8000/upload', { method: 'POST', body: data })
      .then((response) => { response.json().then((body) => { 
          console.log(response);
        });
      });
      setFile(files)
      createRequisito({ variables: { file : data} });
      console.log(data);
    }
  }

  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Editar Requisitos</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">Requisitos
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabindex="0">
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabindex="-1"></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div class="card-content">


          <h4 class="card-title">Catálogo de Requisitos</h4>

          
                                                      <form onSubmit={e => {
                                                              e.preventDefault();
                                                              createRequisito({ variables: { file : file} });
                                                              
                                                          }}>
                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">code</i>
                                                          <input disabled type="text" className="form-control" name="codigo" ref={node => {
                                                              codigo = node;
                                                          }}/>

                                                          
                                                          <label class="active" for="codigo">Código</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="nombre" ref={node => {
                                                              nombre = node;
                                                          }} />
                                                          <label class="active" for="nombre">Requisito</label>
                                                          </div>

                                                          {image &&

                                                          <>

                                                          <div>
                                                              <PDFDownloadLink document={image} fileName="somename.pdf">
                                                                {({ blob, url, loading, error }) =>
                                                                  loading ? 'Loading document...' : 'Download now!'
                                                                }
                                                              </PDFDownloadLink>
                                                            </div>

                                                          <PDFViewer width={800} height={500}
                                                           showToolbar={false}
                                                           document={{
                                                            url: image,
                                                            }}>
                                                          
                                                          </PDFViewer>
                                                          
                                                          <img src={image} style={{ height: 100, width: 100 }} />
                                                          </>
                                                          }

                                                          <input
                                                            type="file"
                                                            onChange={uploadFile}>
                                                          </input>

                                                          <input
                                                            className='App-input'
                                                            type='file'
                                                            onChange={(e) => setFile(e.target.files[0])}
                                                          />
                                                          <button onClick={uploadFile}>Upload</button>
                                                          <p>{LoadingUpload && 'Uploading...'}</p>
                                                          <p>{ErrorUpload?.message}</p>

                                                        

                                                        <div class="row">
                                                            <div class="row">
                                                            <div class="input-field col s12">
                                                            {!loading ? 
                                                              <button class="btn cyan waves-effect waves-light right"
                                                              type="submit" name="action">Enviar
                                                                <i class="material-icons right">send</i>
                                                              </button>
                                                            
                                                              :

                                                              <button class="btn btn-customizer cyan waves-effect waves-light right"
                                                              type="submit" name="action">Procesando
                                                                <i class="material-icons right">sync</i>
                                                              </button>
                                                            
                                                            }
                                                              
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                     
                                                      {error && <p>Error :( {error.message}</p>}

                                                        
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>
                            </div>
      </div>
  );
}

UploadCreateComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default UploadCreateComponent;
