import React, { useState } from "react";
import LoadingComponent from "../../loading.component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery, useLazyQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import 'materialize-css';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import AuthService from "../../../services/auth.service";


const GET_USUARIO = gql`
query usuario($usuarioId: String) {
  viewer {
    usuario(usuarioId: $usuarioId) {
      id
          nombre
          paterno
          materno
          email
          usuario
          estatus
          sexo
          createdAt
          roles{
            edges{
              node{
                id
                name
              }
            }
          }
          categorias{
            edges{
              node{
                id
                nombre
              }
            }
          }
    }
  }
}
`;


function UsuariosPerfilComponent(props) {

  const { location, classes, history } = props;
  const { from } = location.state || { from: { pathname: "/home" } };
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [usuarioId, setCurrentUserID] = useState(currentUser.id);

  const { loading, error, data } = useQuery(GET_USUARIO, {
    variables: { usuarioId },
    onCompleted(data){
      
      console.log(data);
      let usuario  = data.viewer.usuario;
      const roles= [...data.viewer.usuario.roles.edges].map(o => `ROLE_${o.node.name.toUpperCase()}`)
      const categorias= [...data.viewer.usuario.categorias.edges].map(o => `CATEGORIA_${o.node.nombre.toUpperCase()}`)
      //data.viewer.usuario.roles = roles;
      //data.viewer.usuario.categorias = categorias;
      currentUser.categorias = categorias;
      //currentUser.username = `${data.viewer.usuario.toUpperCase()+' '+${data.viewer.usuario.toUpperCase()}`
      console.log(currentUser);
      localStorage.setItem("user", JSON.stringify(currentUser));
      //window.location.reload();
    }
  });

  function actualizaPermisos() {  
    history.push(from.pathname);
          window.location.reload();
  }

  //
  //if (requsitoLoading) return 'Loading...';
  
  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  

  

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Editar Perfil</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">Usuarios
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabIndex="0">
                                <li tabIndex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabIndex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabIndex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabIndex="-1"></li>
                                <li tabIndex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div className="card-content">


          <h4 className="card-title">Catálogo de Usuarios</h4>
                                                {loading ? 
                                                <div>
                                                <Skeleton height={30} count={5} />
                                                </div>
                                                
                                                :
                                                  <>
                                                  
    
      <div class="row">
        <form onSubmit={e => { e.preventDefault(); actualizaPermisos(); }}>          
          <div class="col s12 m12 l12 display-flex align-items-center show-btn">
            <button type="submit" class="btn btn-block indigo waves-effect waves-light">Actualizar permisos</button>
          </div>
        </form>
      </div>
                                                  </>
          
                                                    
                                                      }
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>
                            </div>
      </div>
  );
}

UsuariosPerfilComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default UsuariosPerfilComponent;
