import React, { Component } from "react";

export default class LoadingComponent extends Component {
  
  render() {
    return (
        <div class="progress">
            <div class="indeterminate"></div>
        </div>
    );
  }
}
