import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Moment from 'moment';
import 'moment/locale/es'
import LoadingComponent from "../../loading.component";
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';

export const GET_SUBDESARROLLOS = gql`
query {
  viewer {
    allSubDesarrollos {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
  }
}
`;

const DELETE_SUBDESARROLLO = gql`
  mutation deleteSubDesarrollo($id: ID!) {
    deleteSubDesarrollo(id:$id) {
      success
      subdesarrollo{
        id
        codigo
      }
    }
  }
`;

function SubDesarrollosIndexComponent () {
  const { client, loading, data, error, refetch} = useQuery(GET_SUBDESARROLLOS, 
    { fetchPolicy: "network-only",
    
    onError(error){
      //reset();
      const id = toast.loading("Por favor espere...")
      toast.update(id, { render: `Error! ${error.message}`,
      type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
      autoClose: 5000,  isLoading: false, closeOnClick: true });

    }
  }); 
    
    if (error) return `Error! ${error.message}`;
  
  return (
    
    <div className="section">
        
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Lista de SubDesarrollos</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">SubDesarrollos
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6 right-align">
                          <Link to={`/catalogos/subdesarrollos/crear/nuevo`} type="button"  class="btn waves-effect waves-light mb-1 add-kanban-btn" id="add-kanban">
                            <i class="material-icons left">add</i> Agregar SubDesarrollo
                          </Link>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                    <div className="card-content">
                                        <div className="row">
                                            <div className="col s12">
                                            </div>
                                            <div className="col s12">
                                            <div className="container mt-3"></div>
                                            
                                              <div className="container">
                                              
                                              {loading ? 
                                                <LoadingComponent /> 
                                                :

                                                <header className="jumbotron">
                                                <table class="responsive-table">
                                                  <thead>
                                                    <tr>
                                                      <th data-field="id">Código</th>
                                                      <th data-field="name">Nombre</th>
                                                      <th data-field="price">Creado</th>
                                                      <th data-field="total">Acciones</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                  {
                                                    data.viewer.allSubDesarrollos.edges.map(
                                                        record => 
                                                        <tr key = {record.node.id}>
                                                            <td> {record.node.codigo}</td>
                                                            <td> {record.node.nombre}</td>
                                                            <td> {Moment(record.node.createdAt).format('LLL')} hrs.</td>
                                                            <td className="table-action">
                                                            &nbsp;
                                                                <Mutation mutation={DELETE_SUBDESARROLLO} key={record.node.id} onCompleted={(response) => 
                                                                  {
                                                                    refetch();
                                                                    const id = toast.loading("Por favor espere...")
                                                                    toast.update(id, { render: "Eliminado correctamente",
                                                                    type: "success", position: toast.POSITION.TOP_RIGHT,
                                                                    autoClose: 5000,  isLoading: false, closeOnClick: true });
                                                                    
                                                                    
                                                                  }}>
                                                                    {(deleteSubDesarrollo, { loading, error }) => (
                                                                        <div>
                                                                            <form
                                                                                onSubmit={e => {
                                                                                  e.preventDefault();
                                                                                  Swal.fire({
                                                                                    title: `Estas seguro de eliminar ${record.node.nombre}?`,
                                                                                    //showDenyButton: true,
                                                                                    showCancelButton: true,
                                                                                    position: 'top',
                                                                                    confirmButtonText: 'Aceptar',
                                                                                    cancelButtonText: 'Cancelar',
                                                                                    //denyButtonText: `Don't save`,
                                                                                  }).then((result) => {
                                                                                    /* Read more about isConfirmed, isDenied below */
                                                                                    if (result.isConfirmed) {
                                                                            
                                                                                        deleteSubDesarrollo({ variables: { id: record.node.id } });
                                                                                        //this.setState({content: this.state.content.filter(record => record.id !== id)});
                                                                                    }
                                                                                  });//terina Swal
                                                                                    
                                                                                    //deleteSubDesarrollo({ variables: { id: record.node.id } });
                                                                                }}>
                                                                                  <Link to={`/catalogos/subdesarrollos/${record.node.id}`}
                                                                                   className="mb-6 btn waves-effect waves-light
                                                                                   indigo accent-2"
                                                                                  >
                                                                                      <i class="material-icons">edit</i>
                                                                                  </Link>
                                                                                  &nbsp;
                                                                                  {!loading ? 
                                                                                    <button type="submit" className="mb-6 btn waves-effect waves-light
                                                                                     red accent-2">
                                                                                      <i class="material-icons">delete</i>
                                                                                    </button>
                                                                                  
                                                                                    :

                                                                                    <button type="submit" className="mb-6 btn btn-customizer waves-effect waves-light
                                                                                    red accent-2">
                                                                                    <i class="material-icons">sync</i>
                                                                                  </button>
                                                                                    
                                                                                  
                                                                                  }
                                                                                  
                                                                            </form>
                                                                        
                                                                        {error && <div class="card-alert card red lighten-5">
                                                                                    <div class="card-content red-text">
                                                                                      <p>{`Error! ${error.message}`}</p>
                                                                                    </div>
                                                                                    <button type="button" class="close red-text" data-dismiss="alert" aria-label="Close">
                                                                                      <span aria-hidden="true">×</span>
                                                                                    </button>
                                                                                  </div>}
                                                                        </div>
                                                                    )}
                                                                </Mutation>
                                                                                                                
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                                </table>
                                                </header>
                                                
                                              }
                                              {error ? <div className="card-alert card red lighten-5">
                                                          <div className="card-content red-text">
                                                            <p>{`Error! ${error.message}`}</p>
                                                          </div>
                                                          <button type="button" className="close red-text" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                          </button>
                                                        </div> : null}
                                               
                                              </div>

                                            </div>
                                                
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>
                        </div>  
  
  );
};

SubDesarrollosIndexComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default SubDesarrollosIndexComponent;