import React, { useState } from "react";
import Form from "react-validation/build/form";
import PropTypes from "prop-types";
import LoadingComponent from "../components/loading.component";
import { toast } from 'react-toastify';

import { gql, useMutation } from '@apollo/client';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  button: {
    margin: theme.spacing.unit,
  },
});


const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login( username: $username, password: $password ) {
      user{
        id
        username
        roles{
          edges{
            node{
              id
              name
            }
          }
        }
      }      
      authToken
    }
  }
`;

const Login  = (props) =>{
  const { location, classes, history } = props;
  const { from } = location.state || { from: { pathname: "/home" } };
  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");
  const [login, { data, loading, error, reset }] = useMutation(LOGIN_MUTATION, {
    onCompleted({ login }) {
      if (login) {
        //console.log(login.authToken)
        const roles= [...login.user.roles.edges].map(o => `ROLE_${o.node.name.toUpperCase()}`)
        login.user.roles = roles;
        localStorage.setItem("user", JSON.stringify(login.user));
        localStorage.setItem("authToken", login.authToken);
        setTimeout(() => {
          //document.body.classList.remove('login-bg');
          history.push(from.pathname);
          window.location.reload();

        }, 0);
      }
    },
    onError(error){
      reset();
      const id = toast.loading("Por favor espere...")
      toast.update(id, { render: `Error! ${error.message}`,
      type: "error", position: toast.POSITION.TOP_CENTER, theme: "colored",
      autoClose: 5000,  isLoading: false, closeOnClick: true });
      //reset();
    },
    
    variables: {
      username: username,
      password: password,
    },
  });

  return (
    
    <div id="login-page" className="row">
          <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
            
            <Form
            className="login-form"
            onSubmit={e => { e.preventDefault(); login(); }}>
              <div className="row">
                <div className="input-field col s12">
                  <h5 className="ml-4">Inicio de sesión</h5>
                </div>
              </div>
              <div className="row margin">
                <div className="input-field col s12">
                  <i className="material-icons prefix pt-2">person_outline</i>
                  <input id="username" name="username" type="text" value={username}
                    onChange={e => setUsername(e.target.value)} required/>
                  
                  <label for="username" className="center-align">Usuario</label>
                </div>
              </div>
              <div className="row margin">
                <div className="input-field col s12">
                  <i className="material-icons prefix pt-2">lock_outline</i>
                  
                  <input id="password" type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)} required/>
                  <label for="password">Contraseña</label>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m12 l12 ml-2 mt-1">
                  <p>
                    <label>
                      <input type="checkbox" />
                      <span>Recordarme</span>
                    </label>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <button type="submit"
                    className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12">
                      Iniciar</button>
                </div>
                {loading ? <LoadingComponent /> : null}
                
                
              </div>
              
              {error ? <div className="card-alert card red lighten-5">
                            <div className="card-content red-text">
                              <p>{`Error! ${error.message}`}</p>
                            </div>
                            <button type="button" className="close red-text" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div> : null}
              
              </Form>
          </div>
        </div>
  );
};

Login.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default Login;