import React from "react";
import LoadingComponent from "../../loading.component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";


const GET_TAREA = gql`
query tarea($tareaId: String) {
  viewer {
    tarea(tareaId: $tareaId) {
      id
      codigo
      nombre
      peso
    }
  }
}
`;
const UPDATE_TAREA = gql`
mutation UpdateTareaMutation($tareaData:TareasInput!){
  updateTarea(
    tareaData: $tareaData
  )
  {
  tarea{
    id
    nombre
  }  
  }
}
`;


function TareasEditComponent(tarea) {

  let tareaId = tarea.match.params.id;

  const history = useHistory();
  const { loading: requsitoLoading, error: tareaError, data: tareaData } = useQuery(GET_TAREA, {
    variables: { tareaId },
  }); 

    

  const [updateTarea, { loading, error, reset }] = useMutation(UPDATE_TAREA,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted(){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Tarea actualizado`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });

        history.push({
          pathname:  `/catalogos/tareas`,
          state: {
            response: 'success' 
          } 
        });
        
      }
      
    }
    );
  let codigo, nombre, peso;

  //
  //if (requsitoLoading) return 'Loading...';   
  if (tareaError) return `Error! ${tareaError.message}`;
  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Editar Tareas</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">Tareas
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabindex="0">
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabindex="-1"></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div class="card-content">


          <h4 class="card-title">Catálogo de Tareas</h4>
                                                {requsitoLoading ? 
                                                <LoadingComponent /> 
                                                :

          
                                                      <form onSubmit={e => {
                                                              e.preventDefault();
                                                              updateTarea({ variables: { tareaData : { id: tareaData.viewer.tarea.id,
                                                                 codigo: codigo.value, nombre: nombre.value, peso: peso.value }} });
                                                              
                                                          }}>
                                                          <div class="input-field col s12" style={{display : 'None'}}>
                                                          <i class="material-icons prefix">code</i>
                                                          <input disabled type="text" className="form-control" name="codigo" ref={node => {
                                                              codigo = node;
                                                          }} defaultValue={tareaData.viewer.tarea.codigo} />

                                                          
                                                          <label class="active" for="codigo">Código</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="nombre" ref={node => {
                                                              nombre = node;
                                                          }} defaultValue={tareaData.viewer.tarea.nombre} required/>
                                                          <label class="active" for="nombre">Tarea</label>
                                                          </div>

                                                          <div class="input-field col s12" style={{display : 'None'}}>
                                                          <i class="material-icons prefix">equalizer</i>

                                                          <input type="range" name="peso" ref={node => {
                                                              peso = node;
                                                          }} min="0" max="10" defaultValue={tareaData.viewer.tarea.peso} required />
                                                          <label class="active" for="peso">peso</label>
                                                          </div>

                                                          

                                                        

                                                        <div class="row">
                                                            <div class="row">
                                                            <div class="input-field col s12">
                                                            {!loading ? 
                                                              <button class="btn cyan waves-effect waves-light right"
                                                              type="submit" name="action">Enviar
                                                                <i class="material-icons right">send</i>
                                                              </button>
                                                            
                                                              :

                                                              <button class="btn btn-customizer cyan waves-effect waves-light right"
                                                              type="submit" name="action">Procesando
                                                                <i class="material-icons right">sync</i>
                                                              </button>
                                                            
                                                            }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                      }
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>
                            </div>
      </div>
  );
}

TareasEditComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default TareasEditComponent;
