import React, { useState, useEffect } from "react";
import 'moment/locale/es'
import LoadingComponent from "../loading.component";
import io from "socket.io-client";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '@asseinfo/react-kanban/dist/styles.css';
import { gql, useQuery, useMutation } from '@apollo/client';
import Moment from 'moment';
import { Scrollbar } from "react-scrollbars-custom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import {getRandomColor,createImageFromInitials} from '../Utils';
import AuthService from "../../services/auth.service";
import { Link } from 'react-router-dom';



export const GET_ASIGNACIONACTOS = gql`
query($usuarioId: String) {
  viewer {
    allAsignacionActividades(filters:{estatusNe:"T"}, sort: [CREATED_AT_ASC, CODIGO_ASC]) {
      edges {
        node {
          id
          codigo
          subDesarrollo
          subdesarrolloAsignado {
            id
            codigo
            nombre
          }
          asesor
          asesorAsignado {
            id
            username
            nombre
            paterno
            materno
          }
          actividadAsignado {
            id
            codigo
            nombre
          }
          actividad
          fechaAsignacion
          fechaEstimada
          estatus
          createdAt
          grado
          color
          tipo
          comentarios{
            edges {
              node {
                id
                comentario
                comento
                comentarioPor {
                  id
                  nombre
                  paterno
                  materno
                }
                createdAt
                updatedAt
              }
            }
          }
          asignacionTareas(filters:{estatusNe:"T", ejecutor: $usuarioId}) {
            edges {
              node {
                id
                codigo
                actividad
                comentarios {
                  edges {
                    node {
                      id
                      comentario
                      comento
                      comentarioPor {
                        id
                        nombre
                        paterno
                        materno
                      }
                      createdAt
                      updatedAt
                    }
                  }
                }
                actividadAsignado {
                  id
                  codigo
                  actividadAsignado {
                    id
                    codigo
                    nombre
                  }
                }
                ejecutor
                tarea
                tareaAsignada {
                  id
                  codigo
                  nombre
                }
                asigno
                asignadoPor {
                  id
                  username
                  nombre
                  paterno
                  materno
                }
                ejecutorAsignado {
                  id
                  username
                  nombre
                  paterno
                  materno
                }
                fechaAsignacion
                fechaEstimada
                frecuencia
                estatus
                grado
                color
                tipo
                createdAt
              }
            }
          }
        }
      }
    }
    allActividades {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allUsuarios {
      edges {
        node {
          id
          username
          nombre
          paterno
          materno
        }
      }
    }
    allTareas {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allComentarios {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allSubDesarrollos {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
  }
}
`;

const UPDATE_ASIGNACIONTAREA = gql`
mutation UpdateAsignacionTareaMutation($asignaciontareaData:AsignacionTareasInput!){
  updateAsignacionTarea(
    asignaciontareaData: $asignaciontareaData
  )
  {
  asignaciontarea{
    id
  }  
  }
}
`;

const UPDATE_ASIGNACIONACTO = gql`
mutation UpdateAsignacionActividadMutation($asignacionactividadData:AsignacionActividadesInput!){
  updateAsignacionActividad(
    asignacionactividadData: $asignacionactividadData
  )
  {
  asignacionactividad{
    id
  }  
  }
}
`;

const CREATE_COMENTARIO_TAREA = gql`
mutation CreateAsignacionComentarioMutation($asignacioncomentarioData:AsignacionComentariosInput!){
  createAsignacionComentario(
    asignacioncomentarioData: $asignacioncomentarioData
  )
  {
  asignacioncomentario{
    id
  }  
  }
}
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    zIndex: -1
  }),
}

const estatusOptions = [
  { value: 'P', label: 'En Proceso' },
  { value: 'E', label: 'Pendiente' },
  { value: 'T', label: 'Terminada' },
  { value: 'C', label: 'Cancelada' },
];

const items = {
  tasks: [
      {name:"Actualizar comprobante de domicilio",category:"wip", bgcolor: "orange"},
      {name:"Solicitar carta de no adeudo", category:"wip", bgcolor:"cyan"},
      {name:"Reasasdact", category:"inprogress", bgcolor:"red"},
      {name:"Readsadact", category:"inprogress", bgcolor:"green"},
      {name:"Vue", category:"inreview", bgcolor:"blue-grey"}
    ]
}



function DashIndexComponent() {
  const [socket, setSocket] = useState(null);
  const ENDPOINT = "https://apiwhipe.creasis.mx";
  const [state, setState] = useState(items);
  const [showSidebar, setshowSidebar] = useState(null);
  const [showSidebarActividad, setshowSidebarActividad] = useState(null);
  registerLocale('es', es)
  const [idTarea, setIdTarea] = useState(null);
  const [codigoTarea, setCodigotarea] = useState(null);
  const [gradoTarea, setGradoTarea] = useState(null);
  const [colorTarea, setColorTarea] = useState(null);
  const [tipoTarea, setTipoTarea] = useState(null);
  const [selectedActividad, setActividad] = useState(null);
  const [actividadOptions, setActividadOptions] = useState([]);

  const [selectedEjecutor, setEjecutor] = useState(null);
  const [selectedAsigno, setAsigno] = useState(null);

  const [usuarioOptions, setUsuarioOptions] = useState([]);

  const [selectedTarea, setTarea] = useState(null);
  const [tareaOptions, setTareaOptions] = useState([]);

  const [fechaAsignacion, setFechaAsignacion] = useState(new Date());
  const [fechaEstimada, setFechaEstimada] = useState(new Date());

  const [selectedEstatus, setEstatus] = useState(null);

  const [frecuenciaTarea, setFrecuenciaTarea] = useState(null);

  const [selectedComentarios, setComentarios] = useState([]);
  const [comentariosOptions, setComentariosOptions] = useState([]);

  const [comentarioTarea, setComentarioTarea] = useState(null);
  const [comentariosRealizadosTarea, setComentariosRealizadosTarea] = useState([]);

  const [idActividad, setIdActividad] = useState(null);
  const [codigoActividad, setCodigoActividad] = useState(null);
  const [selectedActividadAsignado, setActividadAsignado] = useState(null);
  const [actividadAsignadoOptions, setActividadAsignadoOptions] = useState([]);
  const [selectedSubDesarolloActividad, setSubDesarolloActividad] = useState(null);
  const [subDesarolloActividadOptions, setSubDesarolloActividadOptions] = useState([]);
  const [selectedAsesorActividad, setAsesorActividad] = useState(null);
  const [fechaAsignacionActividad, setFechaAsignacionActividad] = useState(new Date());
  const [fechaEstimadaActividad, setFechaEstimadaActividad] = useState(new Date());
  const [selectedEstatusActividad, setEstatusActividad] = useState(null);
  const [gradoActividad, setGradoActividad] = useState(null);
  const [colorActividad, setColorActividad] = useState("#ffffff");
  const [tipoActividad, setTipoActividad] = useState(null);
  const [comentariosRealizadosActividad, setComentariosRealizadosActividad] = useState([]);
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());

  const { loading, data, error, refetch} = useQuery(GET_ASIGNACIONACTOS,
    {
      variables: {usuarioId : currentUser.username}
    }, 
    { 
    onCompleted(data){
      const actividades = [...data.viewer.allAsignacionActividades.edges].map(o => 
        ({ value: o.node.codigo, label: `${o.node.codigo} - ${o.node.actividadAsignado.codigo}[${o.node.actividadAsignado.nombre}]` })
      )
      setActividadOptions(actividades);

      const usuarios = [...data.viewer.allUsuarios.edges].map(o => 
        ({ value: o.node.username, label: `${o.node.nombre} ${o.node.paterno} ${o.node.materno}` })
      )
      setUsuarioOptions(usuarios);

      const tareas = [...data.viewer.allTareas.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )
      setTareaOptions(tareas);

      const comentarios = [...data.viewer.allComentarios.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )
      setComentariosOptions(comentarios);

      //Fill Actividades Options
      const actividadesAsignados = [...data.viewer.allActividades.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )
      setActividadAsignadoOptions(actividadesAsignados);

      const subDesarrollosActividades = [...data.viewer.allSubDesarrollos.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )

      setSubDesarolloActividadOptions(subDesarrollosActividades);

    },    
    onError(error){
      //reset();
      const id = toast.loading("Por favor espere...")
      toast.update(id, { render: `Error! ${error.message}`,
      type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
      autoClose: 5000,  isLoading: false, closeOnClick: true });

    }
  }, { notifyOnNetworkStatusChange: true });

  const [updateAsignacionTarea, { data: dataTarea, loading: loadingTarea,
     error: errorTarea, reset }] = useMutation(UPDATE_ASIGNACIONTAREA,
    {
      awaitRefetchQueries: true,
      onError(error){
        toast.dismiss();
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted(data){
        toast.dismiss();
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `AsignacionTarea actualizado`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        setshowSidebar(null);  
        socket.emit('new-message-s', data);    
      }
      
      
    }
    );

    const [updateAsignacionActividad, { data: dataActividad, loading: loadingActividad,
      error: errorActividad, reset: resetActividad }] = useMutation(UPDATE_ASIGNACIONACTO,
      {
        onError(error){
          const id = toast.loading("Por favor espere...")        
          toast.update(id, { render: `Error! ${error.message}`,
          type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
          autoClose: 5000,  isLoading: false, closeOnClick: true });
          resetActividad();
  
        },
        onCompleted(data){
          const id = toast.loading("Por favor espere...")        
          toast.update(id, { render: `AsignacionActividad actualizado`,
          type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
          autoClose: 5000,  isLoading: false, closeOnClick: true });  
          setshowSidebarActividad(null);  
          socket.emit('new-message-s', data);  
        }
        
      }
      );

    const [makeComentario, { data: dataComentarioTarea, loading: loadingComentarioTarea,
      error: errorComentarioTarea, reset : resetComentarioTarea}] = useMutation(CREATE_COMENTARIO_TAREA,
     {
       awaitRefetchQueries: true,
       onError(error){
         toast.dismiss();
         const id = toast.loading("Por favor espere...")        
         toast.update(id, { render: `Error! ${error.message}`,
         type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
         autoClose: 5000,  isLoading: false, closeOnClick: true });
         resetComentarioTarea(); 
       },
       onCompleted(data){
         toast.dismiss();
         const id = toast.loading("Por favor espere...")        
         toast.update(id, { render: `Nuevo comentario`,
         type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
         autoClose: 5000,  isLoading: false, closeOnClick: true });
         setshowSidebar(null);  
         setshowSidebarActividad(null); 
         socket.emit('new-message-s', data);    
       }
       
       
     }
     );

  let color;
    
  
  function onDragStart  (ev, id) {
    console.log('dragstart:',id);
    ev.dataTransfer.setData("id", id);
}

function onDragOver (ev)  {
    ev.preventDefault();
    console.log(ev);
}

function getColor (estatus)  {
  const today = new Date();
  const fecha = new Date(estatus.fechaEstimada);

  if(fecha.getTime() < today.getTime()){
    color = 'red';
    return color;
  }
  switch (estatus.estatus) {
    case 'P':
      return color = 'green';
    case 'E':
      return  color = 'orange';
    case 'T':
      return  color = 'cyan';
    case 'C':
        return  color = 'brown';
    default:
        return null;
  }
  
}

function onDrop (ev, cat)  {
   let id = ev.dataTransfer.getData("id");
   
   let tasks = state.tasks.filter((task) => {
       if (task.name === id) {
           task.category = cat;
       }
       return task;
   });

   setState({
       ...state,
       tasks
   });
}

  

function shoot(tarea) {
  console.log(tarea);
  setshowSidebar("show");
  const labelActividad = `${tarea.actividad} - ${tarea.actividadAsignado.actividadAsignado.codigo}[${tarea.actividadAsignado.actividadAsignado.nombre}]`
  setActividad({ value: tarea.actividad, label: labelActividad })
  setCodigotarea(tarea.codigo);
  setIdTarea(tarea.id);
  setGradoTarea(tarea.grado);
  setColorTarea(tarea.color);
  setTipoTarea(tarea.tipo);
  setEjecutor({ value: tarea.ejecutorAsignado.username,
    label: `${tarea.ejecutorAsignado.nombre} ${tarea.ejecutorAsignado.paterno} ${tarea.ejecutorAsignado.materno}` });
  setTarea({ value: tarea.tareaAsignada.codigo, label: tarea.tareaAsignada.nombre })

  setFechaAsignacion(new Date(tarea.fechaAsignacion));
  setFechaEstimada(new Date(tarea.fechaEstimada));
  setFrecuenciaTarea(tarea.frecuencia);
  setComentariosRealizadosTarea(tarea.comentarios.edges);
  setAsigno({ value: tarea.asignadoPor.username,
    label: `${tarea.asignadoPor.nombre} ${tarea.asignadoPor.paterno} ${tarea.asignadoPor.materno}` });
  
  switch (tarea.estatus) {
      case 'P':
        return setEstatus({ value: 'P', label: 'En Proceso' });
      case 'E':
        return setEstatus({ value: 'E', label: 'Pendiente' });
      case 'T':
        return setEstatus({ value: 'T', label: 'Terminada' });
      case 'C':
        return setEstatus({ value: 'C', label: 'Cancelada' });
      default:
        return null;
  }
  //modal.current.open();
  //setModal(true);
}

function handleClickedActividad(actividad) {
  //console.log(actividad);
  setshowSidebarActividad("show");
  setIdActividad(actividad.id);
  setCodigoActividad(actividad.codigo);
  setActividadAsignado({ value: actividad.actividadAsignado.codigo, label: actividad.actividadAsignado.nombre })
      
  setSubDesarolloActividad({ value: actividad.subdesarrolloAsignado.codigo, label: actividad.subdesarrolloAsignado.nombre })
      
  setAsesorActividad({ value: actividad.asesorAsignado.username,
        label: `${actividad.asesorAsignado.nombre} ${actividad.asesorAsignado.paterno} ${actividad.asesorAsignado.materno}` });
  
  setFechaAsignacionActividad(new Date(actividad.fechaAsignacion));
  setFechaEstimadaActividad(new Date(actividad.fechaEstimada));
  setGradoActividad(actividad.grado);
  setColorActividad(actividad.color);
  setTipoActividad(actividad.tipo);
  setComentariosRealizadosActividad(actividad.comentarios.edges);

  //alert(colorActividad)

  switch (actividad.estatus) {
    case 'P':
      return setEstatusActividad({ value: 'P', label: 'En Proceso' });
    case 'E':
      return setEstatusActividad({ value: 'E', label: 'Pendiente' });
    case 'T':
      return setEstatusActividad({ value: 'T', label: 'Terminada' });
    case 'C':
      return setEstatusActividad({ value: 'C', label: 'Cancelada' });
    default:
      return null;
  }

  


}

function closeSideBar() {
  setshowSidebar(null);
  setshowSidebarActividad(null);
  //refetch();
}

function closeSideBarActividad() {
  setshowSidebar(null);
  setshowSidebarActividad(null);
  //refetch();
}

function handleChangeActividad(selectedOption) {  
  setActividad(selectedOption)
}

function handleChangeEjecutor(selectedOption) {  
  setEjecutor(selectedOption)
}

function handleChangeTarea(selectedOption) {  
  setTarea(selectedOption)
}

function handleChangeAsigno(selectedOption) {  
  setAsigno(selectedOption)
}

function handleChangeFechaAsignacion(selectedDate) {  
  setFechaAsignacion(selectedDate)
}

function handleChangeFechaEstimada(selectedDate) {  
  setFechaEstimada(selectedDate)
}

function handleChangeEstatus(selectedOption) {  
  setEstatus(selectedOption)
}

function handleChangeComentarios(selectedOption) {  
  setComentarios(selectedOption)
  setComentarioTarea(selectedOption.label)
  console.log(selectedOption);
}

function handleChangeComentarioTarea(e) {  
  setComentarioTarea(e.target.value)
  console.log(e.target.value);
}

function handleChangefrecuenciaTarea(e) {  
  setFrecuenciaTarea(e.target.value)
}

function handleChangeActividadAsignado(selectedOption) {  
  setActividadAsignado(selectedOption)
}

function handleSubDesarolloActividad(selectedOption) {  
  setSubDesarolloActividad(selectedOption)
}

function handleChangeAsesorActividad(selectedOption) {  
  setAsesorActividad(selectedOption)
}

function handleChangeFechaAsignacionActividad(selectedDate) {  
  setFechaAsignacionActividad(selectedDate)
}

function handleChangeFechaEstimadaActividad(selectedDate) {  
  setFechaEstimadaActividad(selectedDate)
}

function handleChangeEstatusActividad(selectedOption) {  
  setEstatusActividad(selectedOption)
}

function handleChangeGradoActividad(e) {  
  setGradoActividad(e.target.value)
}

function handleChangeColorActividad(e) {  
  setColorActividad(e.target.value)
}

function handleChangeTipoActividad(e) {  
  setTipoActividad(e.target.value)
}

function getTotalTareas(tareas) {
  if(tareas.length > 0){
    return 'block';
  }else{
    return 'None'
  }
  //refetch();
}


useEffect(() => {
  const socket = io(ENDPOINT);
    setSocket(socket);
    //socket.emit('new-message-s', 'Hi-flask');
    //console.log('new-message');
    //socket.on("operators", operators => {
     // console.log(operators);
    //});

    socket.on('data-tmp', (operators) => {
      console.log(operators, 'data-tmp');
      refetch();
      toast.dismiss();
      const id = toast.loading("Por favor espere...")        
      toast.update(id, { render: `Hubo una actualizacion de tarea`,
      type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
      //this.temperature = data.temperature + '°C';
      //this.humidity = data.humedity;
      //this.color = this.getTempColor(data.temperature);
      });
  if (!showSidebar){
    refetch();
  console.log('useEffect');
  }
    return () => socket.disconnect();
}, [showSidebar, setSocket, refetch])


  if (error) return `Error! ${error.message}`;

  if (errorComentarioTarea) return `Error! ${errorComentarioTarea.message}`;

  if (errorActividad) return `Error! ${errorActividad.message}`;

  if (errorTarea) return `Error! ${errorTarea.message}`;

  return (
    <>
    <div class="col s12">
    <div class="container">
    <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>

<section id="kanban-wrapper" className="section">
    <div className={`kanban-overlay ${showSidebar} ${showSidebarActividad}`} onClick={closeSideBar}></div>
    <div className="row">
        <div className="col s12">

            <Link to={`/procesos/asignaciontareas/crear/nuevo`} type="button"  class="btn waves-effect waves-light mb-1 add-kanban-btn" id="add-kanban">
                            <i class="material-icons left">add</i> Agregar Tareas
            </Link>
            
            
            <div id="kanban-appss">

          {loading ? 
           <LoadingComponent /> 
          :

          <div className="kanban-container" style={{width: 990 + "px"}}>
            


                {
                  data.viewer.allAsignacionActividades.edges.map(record =>
                <div className="kanban-board" data-id={record.node.id} data-order="1" onDragOver={(e)=>onDragOver(e)}
                    onDrop={(e)=>{onDrop(e, "wip")}}
                  style={{ width: 300 + "px", marginLeft: 15 + "px", marginRight: 15 + "px", display: getTotalTareas(record.node.asignacionTareas.edges)}}>
                    <header className="kanban-board-header" style={{ backgroundColor : record.node.color }}>
                        <div className="kanban-title-board">{record.node.actividadAsignado.nombre} {record.node.codigo}</div>
                        <div className="dropdown">
                          <a onClick= {(e) =>handleClickedActividad(record.node)} className="dropdown-trigger" href="#" data-target="1"> <i
                                    className="material-icons white-text">create</i></a>
                            <ul id="1" className="dropdown-content" tabindex="0">
                                <li tabindex="0"><a href="#!"><i className="material-icons">link</i><span className="menu-item">Copy
                                            Link</span></a></li>
                                <li className="kanban-delete" tabindex="0"><a><i className="material-icons">delete</i><span
                                            className="menu-item">Delete</span></a></li>
                            </ul>
                        </div>
                    </header>
                    <main className="kanban-drag">
                    {
                    record.node.asignacionTareas.edges.map(tarea =>

                      

                      <div className="kanban-item" id={tarea.node.id} key={tarea.node.id} onClick= {(e) =>shoot(tarea.node)}
                              onDragStart = {(e) => onDragStart(e, tarea.node.id)}
                              draggable data-eid="1_1" data-border={getColor(tarea.node)} data-duedate="SEPTEMBER 9, 2019"
                        data-comment="1" data-attachment="1" data-users="../../../app-assets/images/avatar/avatar-1.png">
                        {tarea.node.tareaAsignada.nombre}<div className="kanban-footer mt-3">
                            <div className={`kanban-due-date center mb-5 lighten-5 ${getColor(tarea.node)}`}>
                              <span className={`${getColor(tarea.node)}-text center`}>
                                {Moment(tarea.node.fechaEstimada).fromNow(new Date(tarea.node.fechaEstimada) > new Date() ? true : false).toUpperCase()}, {Moment(tarea.node.fechaEstimada).format('dddd, D [de] MMMM [de] YYYY').toUpperCase()}</span></div>
                            <div className="kanban-footer-left left display-flex pt-1">
                                <div className="kanban-comment display-flex"><i
                                        className="material-icons font-size-small">chat_bubble_outline </i><span
                                        className="font-size-small">{tarea.node.comentarios.edges.length}</span></div>
                                <div className="kanban-attachment display-flex"><i
                                        className="font-size-small material-icons">attach_file</i><span
                                        className="font-size-small">1</span></div>
                            </div>
                            <div className="kanban-footer-right right">
                                <div className="kanban-users">
                                  <a>
                                  <img className="circle" onClick={shoot} title={`${tarea.node.ejecutorAsignado.nombre} ${tarea.node.ejecutorAsignado.paterno} ${tarea.node.ejecutorAsignado.materno}`}
                                        src={
                                           createImageFromInitials(100, `${tarea.node.ejecutorAsignado.nombre} ${tarea.node.ejecutorAsignado.paterno}`, getRandomColor())
                                        } alt="Avatar" height="24"
                                        width="24"/>
                                  </a>
                                  </div>
                            </div>
                        </div>
                    </div>
                      
                    )}
                    </main>
                    <footer></footer>
                </div>
                )
                }

                {/*<ModalWindow ref={modal} title="Test" tareaId={tareaSelected}>
                  Hello World
              </ModalWindow>*/}


            
        </div>
         }
              {error ? <div className="card-alert card red lighten-5">
                <div className="card-content red-text">
                  <p>{`Error! ${error.message}`}</p>
                </div>
                <button type="button" className="close red-text" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div> : null}
        </div>
        </div>
        </div>
  
  <div className={`kanban-sidebar ps ps--active-y ${showSidebar} ps--scrolling-y`}>
  <Scrollbar style={{ width: 100 + '%', height: 100 + '%' }}>
    <div className="card quill-wrapper">
      <div className="card-content pt-0">
        <div className="card-header display-flex pb-2">
          <h3 className="card-title">Asignación de Tareas</h3>
          <div className="close close-icon" onClick={closeSideBar}>
            <i className="material-icons">close</i>
          </div>
        </div>
        <div className="divider"></div>
        <form className="edit-kanban-item mt-10 mb-10" 
        onSubmit={e => {
          e.preventDefault();
          updateAsignacionTarea({ variables: { asignaciontareaData : { 
            id: idTarea,
            codigo: codigoTarea,
            actividad: selectedActividad ? selectedActividad.value : null,
            ejecutor: selectedEjecutor ? selectedEjecutor.value : null,
            tarea: selectedTarea ? selectedTarea.value : null,
            fechaAsignacion: fechaAsignacion, fechaEstimada: fechaEstimada,
            frecuencia: frecuenciaTarea, asigno: selectedAsigno ? selectedAsigno.value : null,
            grado : gradoTarea, color : colorTarea, tipo: tipoTarea,
            estatus: selectedEstatus ? selectedEstatus.value : null }} });
          
      }}>
          <div className="input-field">
            <input type="text" className="edit-kanban-item validate" 
            id="edit-item-ejecutor" placeholder="Ejemplo pedrogarcia"
            name="codigo" defaultValue={codigoTarea}/>
            <label for="edit-item-title">Codigo</label>
          </div>
          <div className="input-field">
                  <p>Actividad</p>
                    <Select
                      value={selectedActividad}
                      onChange={handleChangeActividad}
                      options={actividadOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />            
          </div>

          <div className="input-field">
                  <p>Ejecutor</p>
                    <Select
                      value={selectedEjecutor}
                      onChange={handleChangeEjecutor}
                      options={usuarioOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />          
          </div>

          
          <div className="input-field">
                  <p>Tarea</p>
                    <Select
                      value={selectedTarea}
                      onChange={handleChangeTarea}
                      options={tareaOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />          
          </div>

          <div className="input-field">
            <p>Fecha de Asignación</p>
            <DatePicker
            //showTimeSelect
            //dateFormat="Pp"
            locale="es"
            dateFormat="MMMM d, yyyy"
            selected={fechaAsignacion} onChange={handleChangeFechaAsignacion} />
          </div>
          <div className="input-field">
          <p>Fecha Estimada</p>
            <DatePicker
            //showTimeSelect
            //dateFormat="Pp"
            locale="es"
            dateFormat="MMMM d, yyyy"
            selected={fechaEstimada} onChange={handleChangeFechaEstimada} />
          </div>
          <div className="input-field">
            <input type="text" className="edit-kanban-item validate" 
            id="edit-item-recordatorio" defaultValue={frecuenciaTarea}
            onChange={handleChangefrecuenciaTarea} placeholder="Ejemplo 5 horas"/>
            <label for="edit-item-title">Frecuencia Recordatorio</label>
          </div>
          <div className="input-field">
                    <p>Asigno</p>
                    <Select
                      //isMulti
                      name="actividades"
                      value={selectedAsigno}
                      onChange={handleChangeAsigno}
                      options={usuarioOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />
          </div>

          <div className="input-field">
                    <p>Estatus</p>
                    <Select
                      value={selectedEstatus}
                      onChange={handleChangeEstatus}
                      options={estatusOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />
          </div>

          { comentariosRealizadosTarea.length > 0 ?
          <>
          <p>Comentarios</p>
          <div class="sidebar-content sidebar-chat ps ps--active-y" style={{height : 200+'px'}}>
                    <Scrollbar style={{ width: 100 + '%', height: 100 + '%' }}>
                        <div class="chat-list">

                        {
                          comentariosRealizadosTarea.map(
                            record =>
                          <div class="chat-user animate fadeUp delay-3">
                            <div class="user-section">
                              <div class="row valign-wrapper">
                                <div class="col s2 media-image away pr-0">
                                  <img 
                                  src={
                                    createImageFromInitials(100, `${record.node.comentarioPor.nombre} ${record.node.comentarioPor.paterno}`, getRandomColor())
                                 }
                                  alt="" class="circle z-depth-2 responsive-img"/>
                                </div>
                                <div class="col s10">
                                  <p class="m-0 blue-grey-text text-darken-4 font-weight-700">
                                  {record.node.comentarioPor.nombre + ' '+ record.node.comentarioPor.paterno + ' ' + record.node.comentarioPor.materno}
                                  </p>
                                  <p class="m-0 info-text">{record.node.comentario}</p>
                                </div>
                              </div>
                            </div>
                            <div class="info-section">
                              <div class="star-timing">
                                <div class="time">
                                  <span>{Moment(record.node.createdAt).format('LLL')} hrs.</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          )
                          }
                        </div>
                        </Scrollbar>
          </div>
          
          </>
          :
          <p>Sin Comentarios...</p>
          }

          

          <div className="card-action pl-0 pr-0">
          <button type="reset" className="btn-small waves-effect waves-light delete-kanban-item mr-1">
            <span>Eliminar</span>
          </button>
          {!loadingTarea ?
          <button type="submit" className="btn-small blue waves-effect waves-light update-kanban-item">
            <span>Guardar</span>
          </button>
          : 
          <button className="btn-small btn-customizer blue waves-effect waves-light update-kanban-item">
            <span>Procesando
           <i class="material-icons right">sync</i></span>
          </button>
          }
          </div>
        </form>

        <form className="edit-kanban-item mt-10 mb-10" 
                  onSubmit={e => {
                    e.preventDefault();
                    makeComentario({ variables: { asignacioncomentarioData : { 
                      id: idTarea,
                      tareaComentarioCodigo: codigoTarea,
                      actividadComentarioCodigo: null,
                      comentario: comentarioTarea }} });
                    
                }}>
          <div className="input-field">
                  <p>Comentarios prediseñados</p>
                    <Select
                      value={selectedComentarios}
                      //isMulti
                      styles={customStyles}
                      name="comentarios"
                      onChange={handleChangeComentarios}
                      options={comentariosOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />          
          </div>

          <div className="input-field">
          <p>Nuevo Comentario</p>
            <textarea id="textarea2" value={comentarioTarea} onChange={handleChangeComentarioTarea} 
            class="materialize-textarea" required/>
            {!loadingComentarioTarea ?
              <button type="submit" class="btn waves-effect waves-light send">Comentar</button>
            : 
            <button class="btn btn-customizer waves-effect waves-light send">
              <span>Comentando
           <i class="material-icons right">sync</i></span></button>
            }
          </div>

          </form>
        
      </div>
    </div>
    <div class="ps__rail-x" style={{left: 0, bottom: -910}}>
      <div class="ps__thumb-x" tabindex="0" style={{left: 0, width: 0}}></div>
    </div>
    <div class="ps__rail-y" style={{top: 910, height: 349, right: 0}}>
      <div class="ps__thumb-y" tabindex="0" style={{top: 253, height: 96}}></div>
    </div>
    </Scrollbar>
  </div>

  <div className={`kanban-sidebar ps ps--active-y ${showSidebarActividad} ps--scrolling-y`}>
  <Scrollbar style={{ width: 100 + '%', height: 100 + '%' }}>
    <div className="card quill-wrapper">
      <div className="card-content pt-0">
        <div className="card-header display-flex pb-2">
          <h3 className="card-title">Asignación de Actividades</h3>
          <div className="close close-icon" onClick={closeSideBarActividad}>
            <i className="material-icons">close</i>
          </div>
        </div>
        <div className="divider"></div>
        <form className="edit-kanban-item mt-10 mb-10" 
        onSubmit={e => {
          e.preventDefault();
          updateAsignacionActividad({ variables: { asignacionactividadData : {
            id: idActividad, codigo: codigoActividad,
           actividad: selectedActividadAsignado ? selectedActividadAsignado.value : null,
           subDesarrollo : selectedSubDesarolloActividad ? selectedSubDesarolloActividad.value : null,
           asesor : selectedAsesorActividad ? selectedAsesorActividad.value : null,
           grado : gradoActividad, color : colorActividad, tipo: tipoActividad,
           estatus: selectedEstatusActividad ? selectedEstatusActividad.value : null,
           fechaAsignacion: fechaAsignacionActividad, fechaEstimada: fechaEstimadaActividad }} });
          
      }}>
                  <div className="input-field">
                    <input type="text" className="edit-kanban-item validate" 
                    id="edit-item-ejecutor" placeholder="Ejemplo pedrogarcia"
                    name="codigo" defaultValue={codigoActividad}/>
                    <label for="edit-item-title">Codigo</label>
                  </div>

                  <div className="input-field">
                    <p>Actividad</p>
                    <Select
                      value={selectedActividadAsignado}
                      onChange={handleChangeActividadAsignado}
                      options={actividadAsignadoOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />
                  </div>

                  <div className="input-field">
                    <p>Sub Desarrollo</p>
                    <Select
                      value={selectedSubDesarolloActividad}
                      onChange={handleSubDesarolloActividad}
                      options={subDesarolloActividadOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />
                  </div>

                  <div className="input-field">
                    <p>Asesor</p>
                    <Select
                      value={selectedAsesorActividad}
                      onChange={handleChangeAsesorActividad}
                      options={usuarioOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />
                  </div>

                  <div className="input-field">
                    <p>Fecha de Asignación</p>
                    <DatePicker
                      //showTimeSelect
                      //dateFormat="Pp"
                      locale="es"
                      dateFormat="MMMM d, yyyy"
                      selected={fechaAsignacionActividad} onChange={handleChangeFechaAsignacionActividad} />
                  </div>

                  <div class="input-field">
                    <p>Fecha Estimada</p>
                    <DatePicker
                      locale="es"
                      dateFormat="MMMM d, yyyy"
                      selected={fechaEstimadaActividad} onChange={handleChangeFechaEstimadaActividad} />
                  </div>

                  <div class="input-field">
                    <p>Estatus</p>
                    <Select
                      value={selectedEstatusActividad}
                      onChange={handleChangeEstatusActividad}
                      options={estatusOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />
                  </div>

          
          
                  <div className="input-field">
                    <input type="text" className="edit-kanban-item validate"
                      id="grado-actividad" defaultValue={gradoActividad}
                      onChange={handleChangeGradoActividad} placeholder="Ejemplo 5 horas" />
                    <label for="grado-actividad">Grado</label>
                  </div>

                  <div className="input-field s12">
                    <p for="color-actividad">Color</p>
                    <input type="color" className="form-control edit-kanban-item validate"
                      id="color-actividad" value={colorActividad} style={{color: colorActividad}}
                      onChange={handleChangeColorActividad} placeholder="Ejemplo 5 horas" />
                    
                  </div>

                  <div className="input-field">
                    <input type="text" className="edit-kanban-item validate"
                      id="tipo-actividad" defaultValue={tipoActividad}
                      onChange={handleChangeTipoActividad} placeholder="Ejemplo 5 horas" />
                    <label for="tipo-actividad">Tipo</label>
                  </div>
                

                  

          

          

          <div className="card-action pl-0 pr-0">
          <button type="reset" className="btn-small waves-effect waves-light delete-kanban-item mr-1">
            <span>Eliminar</span>
          </button>
          {!loadingActividad ?
          <button type="submit" className="btn-small blue waves-effect waves-light update-kanban-item">
            <span>Guardar</span>
          </button>
          : 
          <button className="btn-small btn-customizer blue waves-effect waves-light update-kanban-item">
            <span>Procesando
           <i class="material-icons right">sync</i></span>
          </button>
          }
          </div>
        </form>

        <form className="edit-kanban-item mt-10 mb-10" 
                  onSubmit={e => {
                    e.preventDefault();
                    makeComentario({ variables: { asignacioncomentarioData : { 
                      id: idActividad,
                      tareaComentarioCodigo: null,
                      actividadComentarioCodigo: codigoActividad,
                      comentario: comentarioTarea }} });
                    
                }}>

{ comentariosRealizadosActividad.length > 0 ?
          <>
          <p>Comentarios</p>
          <div class="sidebar-content sidebar-chat ps ps--active-y" style={{height : 200+'px'}}>
                    <Scrollbar style={{ width: 100 + '%', height: 100 + '%' }}>
                        <div class="chat-list">

                        {
                          comentariosRealizadosActividad.map(
                            record =>
                          <div class="chat-user animate fadeUp delay-3">
                            <div class="user-section">
                              <div class="row valign-wrapper">
                                <div class="col s2 media-image away pr-0">
                                  <img src={
                                    createImageFromInitials(100, `${record.node.comentarioPor.nombre} ${record.node.comentarioPor.paterno}`, getRandomColor())
                                 } alt="" class="circle z-depth-2 responsive-img"/>
                                </div>
                                <div class="col s10">
                                  <p class="m-0 blue-grey-text text-darken-4 font-weight-700">
                                  {record.node.comentarioPor.nombre + ' '+ record.node.comentarioPor.paterno + ' ' + record.node.comentarioPor.materno}
                                  </p>
                                  <p class="m-0 info-text">{record.node.comentario}</p>
                                </div>
                              </div>
                            </div>
                            <div class="info-section">
                              <div class="star-timing">
                                <div class="time">
                                  <span>{Moment(record.node.createdAt).format('LLL')} hrs.</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          )
                          }
                        </div>
                        </Scrollbar>
          </div>
          
          </>
          :
          <p>Sin Comentarios...</p>
          }
          <div className="input-field">
                  <p>Comentarios prediseñados</p>
                    <Select
                      value={selectedComentarios}
                      //isMulti
                      styles={customStyles}
                      name="comentarios"
                      onChange={handleChangeComentarios}
                      options={comentariosOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />          
          </div>

          <div className="input-field">
          <p>Nuevo Comentario</p>
            <textarea id="textarea2" value={comentarioTarea} onChange={handleChangeComentarioTarea} 
            class="materialize-textarea" required/>
            {!loadingComentarioTarea ?
              <button type="submit" class="btn waves-effect waves-light send">Comentar</button>
            : 
            <button class="btn btn-customizer waves-effect waves-light send">
              <span>Comentando
           <i class="material-icons right">sync</i></span></button>
            }
          </div>

          </form>
        
      </div>
    </div>
    <div class="ps__rail-x" style={{left: 0, bottom: -910}}>
      <div class="ps__thumb-x" tabindex="0" style={{left: 0, width: 0}}></div>
    </div>
    <div class="ps__rail-y" style={{top: 910, height: 349, right: 0}}>
      <div class="ps__thumb-y" tabindex="0" style={{top: 253, height: 96}}></div>
    </div>
    </Scrollbar>
  </div>

      </section>
      <br></br>
      <br></br>
      </div>
          <div class="content-overlay"></div>
        </div>


       
    </>
  );
}
export default DashIndexComponent


