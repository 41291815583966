import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:8080/api/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getAllUsers() {
    return axios.get(API_URL + 'catalogos/usuarios', { headers: authHeader() });
  }

  getUsuario(id) {
    return axios.get(API_URL + 'catalogos/usuario/' + id, { headers: authHeader() });
  }

  updateUsuario(id, data) {
    return axios.patch(API_URL + 'catalogos/usuario/' + id, data, { headers: authHeader() });
  }

  addUsuario(data) {
    return axios.post(API_URL + 'catalogos/usuario', data, { headers: authHeader() });
  }

  eliminaUsuario(id) {
    return axios.delete(API_URL + 'catalogos/usuario/' + id, { headers: authHeader() });
  }
}

export default new UserService();
