import React, { useState, useEffect } from 'react';
import {
	BrowserRouter as Router,
	Link,
	useLocation
  } from "react-router-dom";

import AuthService from "../services/auth.service"
import {getRandomColor,createImageFromInitials} from '../components/Utils'


const AsideBarComponent = () => {
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    const [showModeratorBoard, setAsesorBoard] = useState(false);
    const [showAdminBoard, setAdminBoard] = useState(false);
    const [showNotariaBoard, setNotariaBoard] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [toggleStatus, setToggleSideBar] = useState(null);


    function toggleSideBar(){
		var element = document.getElementById("sidebar");
        //element.classList.toggle("nav-collapsed");
        element.classList.toggle("nav-lock");
    }

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        setCurrentUser(user);
        setAdminBoard(user.roles.includes("ROLE_ADMINISTRADOR"));
        setAsesorBoard(user.roles.includes("ROLE_ASESOR"));
        if(user.categorias){
            setNotariaBoard(user.categorias.includes("CATEGORIA_NOTARIA"));
        }
    }, []);

    

    return (
           
            <aside id="sidebar" className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-light sidenav-active-squar">
                <div className="brand-sidebar">
                    <h1 className="logo-wrapper">
                        <Link className="brand-logo darken-1" to={"/home"}>
                        <img className="hide-on-med-and-down" src="../../../app-assets/images/logo/materialize-logo-color.png" alt="materialize logo"/>
                        <img className="show-on-medium-and-down hide-on-med-and-up" src="../../../app-assets/images/logo/materialize-logo.png" alt="materialize logo"/>
                        <span className="logo-text hide-on-med-and-down">Whip</span></Link>
                        <a className="navbar-toggler" onClick={toggleSideBar}>
                            <i className="material-icons">radio_button_checked</i>
                        </a>
                    </h1>
                </div>
                <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">
                    
                    <li className="navigation-header"><a className="navigation-header-text">Applications</a><i className="navigation-header-icon material-icons">more_horiz</i>
                    </li>

                    {showAdminBoard && (

                    <li className={splitLocation[1] === "admin-dash" ? "bold active" : "bold"}>
                        <Link className={splitLocation[1] === "admin-dash" ? "waves-cyan active" : "waves-cyan"} to={"/admin-dash"}>
                        <i className="material-icons">group</i>
                        <span className="menu-title" data-i18n="Chat">Panel Administrador</span></Link>
                    </li>

                    )}

                    <li className={splitLocation[1] === "home" ? "bold active" : "bold"}>
                        <Link className={splitLocation[1] === "home" ? "waves-cyan active" : "waves-cyan"} to={"/home"}>
                        <i className="material-icons">person</i>
                        <span className="menu-title" data-i18n="Chat">Inicio</span></Link>
                    </li>

                    

                    {showAdminBoard && (

                    <li className={splitLocation[1] === "catalogos" ? "bold active open" : "bold"}>
                    <a className="collapsible-header waves-cyan " href="JavaScript:void(0)">
                        <i className="material-icons">menu</i>
                        <span className="menu-title" data-i18n="Templates">Catalogos</span></a>
                    <div className="collapsible-body">
                        <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                        <li>
                            <Link to={"/catalogos/actividades"} className={splitLocation[2] === "actividades" ? "active" : ""}>
                            <i className="material-icons">spellcheck</i>
                            <span data-i18n="Invoice List">Actividades</span></Link>
                        </li>

                        <li className={splitLocation[2] === "tareas" ? "active" : ""}>
                            <Link to={"/catalogos/tareas"} className={splitLocation[2] === "tareas" ? "active" : ""}>
                            <i className="material-icons">work</i>
                            <span data-i18n="Invoice List">Tareas</span></Link>
                        </li>

                        {showNotariaBoard && (
                            <>
                        <li className={splitLocation[2] === "desarrollos" ? "active" : ""}>
                            <Link to={"/catalogos/desarrollos"} className={splitLocation[2] === "desarrollos" ? "active" : ""}>
                            <i className="material-icons">work</i>
                            <span data-i18n="Invoice List">Desarrollo</span></Link>
                        </li>
                        

                        <li className={splitLocation[2] === "subdesarrollos" ? "active" : ""}>
                            <Link to={"/catalogos/subdesarrollos"} className={splitLocation[2] === "subdesarrollos" ? "active" : ""}>
                            <i className="material-icons">work</i>
                            <span data-i18n="Invoice List">Sub-Desarrollo</span></Link>
                        </li>
                        </>

                        )
                        }

                        <li>
                            <Link to={"/catalogos/requisitos"} className={splitLocation[2] === "requisitos" ? "active" : ""}>
                            <i className="material-icons">spellcheck</i>
                            <span data-i18n="Invoice List">Requisitos</span></Link>
                        </li>

                        <li className={splitLocation[2] === "usuarios" ? "active" : ""}>
                            <Link to={"/catalogos/usuarios"} className={splitLocation[2] === "usuarios" ? "active" : ""}>
                            <i className="material-icons">person</i>
                            <span data-i18n="Invoice List">Usuarios</span></Link>
                        </li>
                        <li>
                            <Link to={"/catalogos/contactos"} className={splitLocation[2] === "contactos" ? "active" : ""}>
                            <i className="material-icons">security</i>
                            <span data-i18n="Invoice List">Contactos</span></Link>
                        </li>
                        
                        <li className={splitLocation[2] === "comentarios" ? "active" : ""}>
                            <Link to={"/catalogos/comentarios"} className={splitLocation[2] === "comentarios" ? "active" : ""}>
                            <i className="material-icons">comment</i>
                            <span data-i18n="Invoice List">Comentarios</span></Link>
                        </li>

                        {showNotariaBoard && (

                        <li className={splitLocation[2] === "etapas" ? "active" : ""}>
                            <Link to={"/catalogos/etapas"} className={splitLocation[2] === "etapas" ? "active" : ""}>
                            <i className="material-icons">view_column</i>
                            <span data-i18n="Invoice List">Etapas</span></Link>
                        </li>
                        )}
                                           
                        </ul>
                    </div>
                    </li>

                    

                    
                    )}

                    

                    <li className={splitLocation[1] === "procesos" ? "bold active open" : "bold"}>
                    <a className="collapsible-header waves-cyan " href="JavaScript:void(0)">
                        <i className="material-icons">menu</i>
                        <span className="menu-title" data-i18n="Templates">Procesos</span></a>
                    <div className="collapsible-body">
                        <ul className="collapsible collapsible-sub" data-collapsible="accordion">

                        <li style={{overflow: 'hidden',
                        whiteSpace: 'nowrap',  textOverflow: 'clip'}}>
                            <Link to={"/procesos/asignacionactividades"}
                             className={splitLocation[2] === "asignacionactividades" ? "active" : ""}>
                            <i className="material-icons">work</i>
                            <span data-i18n="Invoice List">Asignación de Actividades</span></Link>
                        </li>

                        {showNotariaBoard && (   

                        <li >
                            <Link to={"/procesos/asignaciontareas"} className={splitLocation[2] === "asignaciontareas" ? "active" : ""}>
                            <i className="material-icons">work</i>
                            <span data-i18n="Invoice List" >Asignación de Tareas</span></Link>
                        </li>
                        
                        )}             
                        
                        
                        </ul>
                    </div>
                    </li>

                    <li className={splitLocation[1] === "reportes" ? "bold active open" : "bold"}>
                    <a className="collapsible-header waves-cyan " href="JavaScript:void(0)">
                        <i className="material-icons">menu</i>
                        <span className="menu-title" data-i18n="Templates">Reportes</span></a>
                    <div className="collapsible-body">
                        <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                        <li>
                            <Link to={"/reportes/users"} className={splitLocation[2] === "users" ? "active" : ""}>
                            <i className="material-icons">spellcheck</i>
                            <span data-i18n="Invoice List">Usuarios</span></Link>
                        </li>

                        {/*<li>
                            <Link to={"/dinamico/users"} className={splitLocation[2] === "users" ? "" : ""}>
                            <i className="material-icons">spellcheck</i>
                            <span data-i18n="Invoice List">Usuarios Ejemplo</span></Link>
                        </li>*/}

                        <li className={splitLocation[2] === "actividades-vencidas" ? "active" : ""}>
                            <Link to={"/reportes/actividades-vencidas"} className={splitLocation[2] === "actividades-vencidas" ? "active" : ""}>
                            <i className="material-icons">work</i>
                            <span data-i18n="Invoice List">Actividades Vencidas</span></Link>
                        </li>

                        <li className={splitLocation[2] === "tareas-ejecutor" ? "active" : ""}>
                            <Link to={"/reportes/tareas-ejecutor"} className={splitLocation[2] === "tareas-ejecutor" ? "active" : ""}>
                            <i className="material-icons">work</i>
                            <span data-i18n="Invoice List">Tareas por Ejecutor</span></Link>
                        </li>
                        
                        
                        </ul>
                    </div>
                    </li>


                    
                
                    
                    
                    
                </ul>
                <div className="navigation-background"></div><a className="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-light hide-on-large-only" href="#" data-target="slide-out"><i className="material-icons">menu</i></a>
                </aside>
           
        )
    }


export default AsideBarComponent
