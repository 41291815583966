import React, { Component, lazy } from "react";
import { Switch, Route, Link, 
	} from "react-router-dom";
import { ToastContainer } from 'react-toastify';

//import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import {getRandomColor,createImageFromInitials} from './components/Utils'


import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import UsuariosPerfilComponent from "./components/catalogos/usuarios/usuarios-perfil.component";
import UsuariosIndexComponent from "./components/catalogos/usuarios/usuarios-index.component";
import UsuariosEditComponent from "./components/catalogos/usuarios/usuarios-edit.component";
import UsuariosCreateComponent from "./components/catalogos/usuarios/usuarios-create.component";

// import AuthVerify from "./common/auth-verify";
import RequisitosIndexComponent from "./components/catalogos/requisitos/requisitos-index.component";
import RequisitosEditComponent from "./components/catalogos/requisitos/requisitos-edit.component";
import RequisitosCreateComponent from "./components/catalogos/requisitos/requisitos-create.component";

import RolesIndexComponent from "./components/catalogos/roles/roles-index.component";

import ActosIndexComponent from "./components/catalogos/actos/actos-index.component";
import ActosCreateComponent from "./components/catalogos/actos/actos-create.component";
import ActosEditComponent from "./components/catalogos/actos/actos-edit.component";

import ActividadesIndexComponent from "./components/catalogos/actividades/actividades-index.component";
import ActividadesCreateComponent from "./components/catalogos/actividades/actividades-create.component";
import ActividadesEditComponent from "./components/catalogos/actividades/actividades-edit.component";

import EtapasIndexComponent from "./components/catalogos/etapas/etapas-index.component";
import EtapasCreateComponent from "./components/catalogos/etapas/etapas-create.component";
import EtapasEditComponent from "./components/catalogos/etapas/etapas-edit.component";

import TareasIndexComponent from "./components/catalogos/tareas/tareas-index.component";
import TareasCreateComponent from "./components/catalogos/tareas/tareas-create.component";
import TareasEditComponent from "./components/catalogos/tareas/tareas-edit.component";

import ComentariosIndexComponent from "./components/catalogos/comentarios/comentarios-index.component";
import ComentariosCreateComponent from "./components/catalogos/comentarios/comentarios-create.component";
import ComentariosEditComponent from "./components/catalogos/comentarios/comentarios-edit.component";

import ContactosIndexComponent from "./components/catalogos/contactos/contactos-index.component";
import ContactosCreateComponent from "./components/catalogos/contactos/contactos-create.component";
import ContactosEditComponent from "./components/catalogos/contactos/contactos-edit.component";

import SubDesarrollosIndexComponent from "./components/catalogos/subdesarrollo/subdesarrollo-index.component";
import SubDesarrollosCreateComponent from "./components/catalogos/subdesarrollo/subdesarrollo-create.component";
import SubDesarrollosEditComponent from "./components/catalogos/subdesarrollo/subdesarrollo-edit.component";

import DesarrollosIndexComponent from "./components/catalogos/desarrollo/desarrollo-index.component";
import DesarrollosCreateComponent from "./components/catalogos/desarrollo/desarrollo-create.component";
import DesarrollosEditComponent from "./components/catalogos/desarrollo/desarrollo-edit.component";
import UploadCreateComponent from "./components/catalogos/upload/upload-create.component";

import ProTareasIndexComponent from "./components/procesos/tareas/pro-tareas-index.component";
import AsignacionTareasEditComponent from "./components/procesos/tareas/pro-tareas-edit.component";
import AsignacionTareasCreateComponent from "./components/procesos/tareas/pro-tareas-create.component";

import ProActosIndexComponent from "./components/procesos/actos/pro-actos-index.component";
import AsignacionActosEditComponent from "./components/procesos/actos/pro-actos-edit.component";
import AsignacionActosCreateComponent from "./components/procesos/actos/pro-actos-create.component";

import ProActividadesIndexComponent from "./components/procesos/actividades/pro-actividades-index.component";
import AsignacionActividadesEditComponent from "./components/procesos/actividades/pro-actividades-edit.component";
import AsignacionActividadesCreateComponent from "./components/procesos/actividades/pro-actividades-create.component";


import DashIndexComponent from "./components/tareas/dash-index.component"
import DashAdminComponent from "./components/tareas/dash-admin.component"


import GuardedRoute from './components/GuardedRoute';
import AsideBarComponent from './components/AsideBarComponent';
import NotificationsComponent from './components/NotificationsComponent';

import HeaderComponent from './components/HeaderComponent';
import RightSideBarNavComponent from './components/RightSideBarNavComponent';
import ThemeCutomizerSideNavComponent from './components/ThemeCutomizerSideNavComponent';
import FooterComponent from './components/FooterComponent';

import MovieList from "./components/reportes/usuarios/index.componet"
import UsuariosPDFDinamico from "./components/reportes/usuarios/dinamico.componet"
import ReporteTareasEjecutor from "./components/reportes/tareas/index.componet"
import ReporteActosVencidos from "./components/reportes/actos/index.componet"
import ReporteActividadesVencidas from "./components/reportes/actividades/index.componet"









import EventBus from "./common/EventBus";
//const NavBarComponent = lazy(() => import('./components/NavBarComponent'));





class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    

    //if (user) {
    if (user) {
      document.body.classList.remove('login-bg');
      this.setState({
        currentUser: user,
        //showModeratorBoard: user.roles.includes("ROLE_ASESOR"),
        //showAdminBoard: user.roles.includes("ROLE_ADMINISTRADOR"),
        showModeratorBoard: true,
        showAdminBoard: true,
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });

    if (!("Notification" in window)) {

      console.log("This browser does not support desktop notification");

    } else {

      Notification.requestPermission();

    }
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      
      <div>
          { (currentUser) ?
          <>
    <header className="page-topbar" id="header">
      <div className="navbar navbar-fixed"> 
        <nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-dark gradient-45deg-indigo-purple no-shadow">
          <div className="nav-wrapper">
            <div className="header-search-wrapper hide-on-med-and-down">
                
            </div>
            <ul className="navbar-list right">
                    <NotificationsComponent />
                      
              
              <li>
                  <a className="profile-button" href="javascript:void(0);" data-target="profile-dropdown"><span className="avatar-status avatar-online">
                    <img src={createImageFromInitials(100, `${currentUser.username}`, getRandomColor())
                                        } alt="avatar"/>
                    <i></i></span>
                  </a></li>
              
            </ul>
            <ul className="dropdown-content" id="translation-dropdown">
              <li className="dropdown-item"><a className="grey-text text-darken-1" href="#!" data-language="en"><i className="flag-icon flag-icon-gb"></i> English</a></li>
              <li className="dropdown-item"><a className="grey-text text-darken-1" href="#!" data-language="fr"><i className="flag-icon flag-icon-fr"></i> French</a></li>
              <li className="dropdown-item"><a className="grey-text text-darken-1" href="#!" data-language="pt"><i className="flag-icon flag-icon-pt"></i> Portuguese</a></li>
              <li className="dropdown-item"><a className="grey-text text-darken-1" href="#!" data-language="de"><i className="flag-icon flag-icon-de"></i> German</a></li>
            </ul>
            <ul className="dropdown-content" id="notifications-dropdown">
              <li>
                <h6>NOTIFICATIONS<span className="new badge">5</span></h6>
              </li>
              <li className="divider"></li>
              <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle cyan small">add_shopping_cart</span> A new order has been placed!</a>
                <time className="media-meta grey-text darken-2" datetime="2015-06-12T20:50:48+08:00">2 hours ago</time>
              </li>
              <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle red small">stars</span> Completed the task</a>
                <time className="media-meta grey-text darken-2" datetime="2015-06-12T20:50:48+08:00">3 days ago</time>
              </li>
              <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle teal small">settings</span> Settings updated</a>
                <time className="media-meta grey-text darken-2" datetime="2015-06-12T20:50:48+08:00">4 days ago</time>
              </li>
              <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle deep-orange small">today</span> Director meeting started</a>
                <time className="media-meta grey-text darken-2" datetime="2015-06-12T20:50:48+08:00">6 days ago</time>
              </li>
              <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle amber small">trending_up</span> Generate monthly report</a>
                <time className="media-meta grey-text darken-2" datetime="2015-06-12T20:50:48+08:00">1 week ago</time>
              </li>
            </ul>
            <ul className="dropdown-content" id="profile-dropdown">
              <li>
                <Link className="grey-text text-darken-1" to={"/usuario/perfil"}>
                  <i className="material-icons">person_outline</i> Perfil
                </Link>
              </li>
              
              <li>
                <a className="grey-text text-darken-1" onClick={this.logOut} href="/login">
                  <i className="material-icons">keyboard_tab</i> Salir
                </a>
              </li>

            </ul>
          </div>
          <nav className="display-none search-sm">
            <div className="nav-wrapper">
              <form id="navbarForm">
                <div className="input-field search-input-sm">
                  <input className="search-box-sm mb-0" type="search" required="" id="search" placeholder="Explore Materialize" data-search="template-list"/>
                  <label className="label-icon" for="search"><i className="material-icons search-sm-icon">search</i></label><i className="material-icons search-sm-close">close</i>
                  <ul className="search-list collection search-list-sm display-none"></ul>
                </div>
              </form>
            </div>
          </nav>
        </nav>
      </div>
    </header>

    <ul className="display-none" id="default-search-main">
      <li className="auto-suggestion-title"><a className="collection-item" href="#">
          <h6 className="search-title">FILES</h6></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img src="../../../app-assets/images/icon/pdf-image.png" width="24" height="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Two new item submitted</span><small className="grey-text">Marketing Manager</small></div>
            </div>
            <div className="status"><small className="grey-text">17kb</small></div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img src="../../../app-assets/images/icon/doc-image.png" width="24" height="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">52 Doc file Generator</span><small className="grey-text">FontEnd Developer</small></div>
            </div>
            <div className="status"><small className="grey-text">550kb</small></div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img src="../../../app-assets/images/icon/xls-image.png" width="24" height="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">25 Xls File Uploaded</span><small className="grey-text">Digital Marketing Manager</small></div>
            </div>
            <div className="status"><small className="grey-text">20kb</small></div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img src="../../../app-assets/images/icon/jpg-image.png" width="24" height="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Anna Strong</span><small className="grey-text">Web Designer</small></div>
            </div>
            <div className="status"><small className="grey-text">37kb</small></div>
          </div></a></li>
      <li className="auto-suggestion-title"><a className="collection-item" href="#">
          <h6 className="search-title">MEMBERS</h6></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar">
                <img className="circle" src={createImageFromInitials(100, `${currentUser.username}`, getRandomColor())
                                        } width="30" alt="sample image"/>
              </div>
              <div className="member-info display-flex flex-column"><span className="black-text">John Doe</span><small className="grey-text">UI designer</small></div>
            </div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img className="circle" src="../../../app-assets/images/avatar/avatar-8.png" width="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Michal Clark</span><small className="grey-text">FontEnd Developer</small></div>
            </div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img className="circle" src="../../../app-assets/images/avatar/avatar-10.png" width="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Milena Gibson</span><small className="grey-text">Digital Marketing</small></div>
            </div>
          </div></a></li>
      <li className="auto-suggestion"><a className="collection-item" href="#">
          <div className="display-flex">
            <div className="display-flex align-item-center flex-grow-1">
              <div className="avatar"><img className="circle" src="../../../app-assets/images/avatar/avatar-12.png" width="30" alt="sample image"/></div>
              <div className="member-info display-flex flex-column"><span className="black-text">Anna Strong</span><small className="grey-text">Web Designer</small></div>
            </div>
          </div></a></li>
    </ul>
    <ul className="display-none" id="page-search-title">
      <li className="auto-suggestion-title"><a className="collection-item" href="#">
          <h6 className="search-title">PAGES</h6></a></li>
    </ul>
    <ul className="display-none" id="search-not-found">
      <li className="auto-suggestion"><a className="collection-item display-flex align-items-center" href="#"><span className="material-icons">error_outline</span><span className="member-info">No results found.</span></a></li>
    </ul>
    </>
    : null
}


    { (currentUser) ? 
    <>
    <AsideBarComponent />
    </>
        : null
    }
        
        
        {/*Main Div*/}
        <div id={ (currentUser) ?  "main" : null}>


        <div className="row">
        
            <div className="col s12">
                <div className="container">
                <ToastContainer autoClose={5000}/>
                    
                    
                    
                    
                                              <Switch>
                                                <GuardedRoute exact path={["/", "/home"]} component={DashIndexComponent} />
                                                <GuardedRoute exact path={["/", "/admin-dash"]} component={DashAdminComponent} />
                                                <Route exact path="/login" component={Login} />
                                                <Route exact path="/authorize" component={Login} />
                                                <Route exact path="/register" component={Register} />
                                                <GuardedRoute exact path="/profile" component={Profile} />
                                                <GuardedRoute path="/user" component={BoardUser} />
                                                <GuardedRoute path="/mod" component={BoardModerator} />
                                                <GuardedRoute path="/admin" component={BoardAdmin} />
                                                <GuardedRoute exact path="/usuario/perfil" component={UsuariosPerfilComponent} />
                                                <GuardedRoute exact path="/catalogos/usuarios" component={UsuariosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/usuarios/:id" component={UsuariosEditComponent} />
                                                <GuardedRoute exact path="/catalogos/usuarios/crear/nuevo" component={UsuariosCreateComponent} />

                                                <GuardedRoute exact path="/upload" component={UploadCreateComponent} />


                                                <GuardedRoute exact path="/catalogos/roles" component={RolesIndexComponent} />

                                                <GuardedRoute exact path="/catalogos/actividades" component={ActividadesIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/actividades/crear/nuevo" component={ActividadesCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/actividades/:id" component={ActividadesEditComponent} />

                                                <GuardedRoute exact path="/catalogos/etapas" component={EtapasIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/etapas/crear/nuevo" component={EtapasCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/etapas/:id" component={EtapasEditComponent} />

                                                <GuardedRoute exact path="/catalogos/tareas" component={TareasIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/tareas/crear/nuevo" component={TareasCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/tareas/:id" component={TareasEditComponent} />

                                                <GuardedRoute exact path="/catalogos/subdesarrollos" component={SubDesarrollosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/subdesarrollos/crear/nuevo" component={SubDesarrollosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/subdesarrollos/:id" component={SubDesarrollosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/desarrollos" component={DesarrollosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/desarrollos/crear/nuevo" component={DesarrollosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/desarrollos/:id" component={DesarrollosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/contactos" component={ContactosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/contactos/crear/nuevo" component={ContactosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/contactos/:id" component={ContactosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/comentarios" component={ComentariosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/comentarios/crear/nuevo" component={ComentariosCreateComponent} />
                                                <GuardedRoute exact path="/catalogos/comentarios/:id" component={ComentariosEditComponent} />

                                                <GuardedRoute exact path="/catalogos/requisitos" component={RequisitosIndexComponent} />
                                                <GuardedRoute exact path="/catalogos/requisitos/:id" component={RequisitosEditComponent} />
                                                <GuardedRoute exact path="/catalogos/requisitos/crear/nuevo" component={RequisitosCreateComponent} />

                                                <GuardedRoute exact path="/procesos/asignaciontareas" component={ProTareasIndexComponent} />
                                                <GuardedRoute exact path="/procesos/asignaciontareas/:id" component={AsignacionTareasEditComponent} />
                                                <GuardedRoute exact path="/procesos/asignaciontareas/crear/nuevo" component={AsignacionTareasCreateComponent} />


                                                <GuardedRoute exact path="/procesos/asignacionactos" component={ProActosIndexComponent} />
                                                <GuardedRoute exact path="/procesos/asignacionactos/:id" component={AsignacionActosEditComponent} />
                                                <GuardedRoute exact path="/procesos/asignacionactos/crear/nuevo" component={AsignacionActosCreateComponent} />

                                                <GuardedRoute exact path="/procesos/asignacionactividades" component={ProActividadesIndexComponent} />
                                                <GuardedRoute exact path="/procesos/asignacionactividades/:id" component={AsignacionActividadesEditComponent} />
                                                <GuardedRoute exact path="/procesos/asignacionactividades/crear/nuevo" component={AsignacionActividadesCreateComponent} />


                                                <GuardedRoute exact path="/home" component={DashIndexComponent} />
                                                <GuardedRoute exact path="/reporte/users" component={MovieList} />
                                                <GuardedRoute exact path="/reportes/users" component={MovieList} />
                                                <GuardedRoute exact path="/dinamico/users" component={UsuariosPDFDinamico} />
                                                <GuardedRoute exact path="/reportes/tareas-ejecutor" component={ReporteTareasEjecutor} />
                                                <GuardedRoute exact path="/reportes/actos-vencidos" component={ReporteActosVencidos} />
                                                <GuardedRoute exact path="/reportes/actividades-vencidas" component={ReporteActividadesVencidas} />

                                                
                                              </Switch>
                                            


        { (currentUser) ?
        <RightSideBarNavComponent />
                    : null
                }

                
        </div>
        <div class="content-overlay"></div>
        </div>
        </div>

        {/*End MainDiv*/}
        </div>

        { (currentUser) ?
        <ThemeCutomizerSideNavComponent />
        : null
        }

    { (false) ?
        <FooterComponent />
        : null
    }
    

        { /*<AuthVerify logOut={this.logOut}/> */ }
      </div>
    );
  }
}

export default App;
