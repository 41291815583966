import React, { useState, useEffect } from "react";
import LoadingComponent from "../../loading.component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import io from "socket.io-client";


const GET_DATA = gql`
query{
  viewer {
    allAsignacionActividades{
      edges{
        node{
          id
          codigo
          actividad
          actividadAsignado{
            codigo
            nombre
          }
          subDesarrollo
          asesor
          fechaAsignacion
          fechaEstimada
          createdAt        
        }
      }
    }
    allActividades {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allUsuarios {
      edges {
        node {
          id
          username
          nombre
          paterno
          materno
        }
      }
    }
    allTareas {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allTipoCategorias {
      edges {
        node {
          id
          nombre
          createdAt
          updatedAt
        }
      }
    }
  }
}
`;
const UPDATE_ASIGNACIONTAREA = gql`
mutation CreateAsignacionTareaMutation($asignaciontareaData:AsignacionTareasInput!){
  createAsignacionTarea(
    asignaciontareaData: $asignaciontareaData
  )
  {
  asignaciontarea{
    id
  }  
  }
}
`;
const estatusOptions = [
  { value: 'P', label: 'En Proceso' },
  { value: 'E', label: 'Pendiente' },
  { value: 'T', label: 'Terminada' },
  { value: 'C', label: 'Cancelada' },
];


function AsignacionTareasCreatComponent(asignaciontarea) {

  let asignaciontareaId = asignaciontarea.match.params.id;
  registerLocale('es', es)  
  const [selectedActividad, setActividad] = useState(null);
  const [actividadOptions, setActividadOptions] = useState([]);

  const [selectedEjecutor, setEjecutor] = useState(null);
  const [selectedAsigno, setAsigno] = useState(null);

  const [usuarioOptions, setUsuarioOptions] = useState([]);

  const [selectedTarea, setTarea] = useState(null);
  const [tareaOptions, setTareaOptions] = useState([]);

  const [startDate, setStartDate] = useState(new Date());

  const [fechaAsignacion, setFechaAsignacion] = useState(new Date());
  const [fechaEstimada, setFechaEstimada] = useState(new Date());

  const [selectedEstatus, setEstatus] = useState({ value: "P", label: "En Proceso" });

  const [selectedTipoCategoria, setTipoCategoria] = useState({ value: "VGlwb0NhdGVnb3JpYVR5cGU6Mg==", label: "Personal" });
  const [tipoCategoriaOptions, setTipoCategoriaOptions] = useState([]);

  
  const [value, onChange] = useState(new Date());
  const [socket, setSocket] = useState(null);
  const ENDPOINT = "https://apiwhipe.creasis.mx";

  const history = useHistory();
  const { loading: requsitoLoading, error: asignaciontareaError, data: asignaciontareaData } = useQuery(GET_DATA, {
    onCompleted(data){

      const actividades = [...data.viewer.allAsignacionActividades.edges].map(o => 
        ({ value: o.node.codigo, label: `${o.node.codigo} - ${o.node.actividadAsignado.codigo}[${o.node.actividadAsignado.nombre}]` })
      )
      setActividadOptions(actividades);

      const usuarios = [...data.viewer.allUsuarios.edges].map(o => 
        ({ value: o.node.username, label: `${o.node.nombre} ${o.node.paterno} ${o.node.materno}` })
      )
      setUsuarioOptions(usuarios);

      const tareas = [...data.viewer.allTareas.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )
      setTareaOptions(tareas);

      const tipoCategorias = [...data.viewer.allTipoCategorias.edges].map(o => 
        ({ value: o.node.id, label: o.node.nombre })
    )

    setTipoCategoriaOptions(tipoCategorias);

    }
  }); 

    

  const [createAsignacionTarea, { data, loading, error, reset }] = useMutation(UPDATE_ASIGNACIONTAREA,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted(){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `AsignacionTarea actualizado`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        socket.emit('new-message-s', data);

        history.push({
          pathname:  `/procesos/asignaciontareas`,
          state: {
            response: 'success' 
          } 
        });
        
      }
      
    }
    );
    let nombre, codigo, color, grado, tipo, frecuencia;

  function handleChangeActividad(selectedOption) {  
    setActividad(selectedOption)
  }

  function handleChangeEjecutor(selectedOption) {  
    setEjecutor(selectedOption)
  }

  function handleChangeTarea(selectedOption) {  
    setTarea(selectedOption)
  }

  function handleChangeAsigno(selectedOption) {  
    setAsigno(selectedOption)
  }

  function handleChangeFechaAsignacion(selectedDate) {  
    setFechaAsignacion(selectedDate)
  }

  function handleChangeFechaEstimada(selectedDate) {  
    setFechaEstimada(selectedDate)
  }

  function handleChangeEstatus(selectedOption) {  
    setEstatus(selectedOption)
  }

  function handleTipoCategoria(selectedOption) {  
    setTipoCategoria(selectedOption)
  }

  useEffect(() => {
    const socket = io(ENDPOINT);
      setSocket(socket);
      return () => socket.disconnect();
  }, [setSocket])

  //
  //if (requsitoLoading) return 'Loading...';   
  if (asignaciontareaError) return `Error! ${asignaciontareaError.message}`;
  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Crear AsignacionTareas</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">AsignacionTareas
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabindex="0">
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabindex="-1"></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div class="card-content">


          <h4 class="card-title">Catálogo de AsignacionTareas</h4>
                                                {requsitoLoading ? 
                                                <LoadingComponent /> 
                                                :

          
                                                      <form onSubmit={e => {
                                                              e.preventDefault();
                                                              createAsignacionTarea({ variables: { asignaciontareaData : {
                                                                codigo: codigo.value,
                                                                actividad: selectedActividad ? selectedActividad.value : null,
                                                                ejecutor: selectedEjecutor ? selectedEjecutor.value : null,
                                                                tarea: selectedTarea ? selectedTarea.value : null,
                                                                fechaAsignacion: fechaAsignacion, fechaEstimada: fechaEstimada,
                                                                frecuencia: frecuencia.value, asigno: selectedAsigno ? selectedAsigno.value : "",
                                                                grado : grado.value, color : color.value, tipo: tipo.value,
                                                                estatus: selectedEstatus ? selectedEstatus.value : null }} });
                                                              
                                                          }}>
                                                          <div class="input-field col s12" style={{display : 'None'}}>
                                                          <i class="material-icons prefix">code</i>
                                                          <input disabled type="text" className="form-control" name="codigo" ref={node => {
                                                              codigo = node;
                                                          }} />

                                                          
                                                          <label class="active" for="codigo">Código</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Actividad</p>
                                                            <Select
                                                              value={selectedActividad}
                                                              onChange={handleChangeActividad}
                                                              options={actividadOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Ejecutor</p>
                                                            <Select
                                                              value={selectedEjecutor}
                                                              onChange={handleChangeEjecutor}
                                                              options={usuarioOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Tarea</p>
                                                            <Select
                                                              value={selectedTarea}
                                                              onChange={handleChangeTarea}
                                                              options={tareaOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s6">
                                                            <p>Fecha de Asignación</p>
                                                            <DatePicker
                                                             //showTimeSelect
                                                             //dateFormat="Pp"
                                                             locale="es"
                                                             dateFormat="MMMM d, yyyy"
                                                             selected={fechaAsignacion} onChange={handleChangeFechaAsignacion} />
                                                          </div>

                                                          <div class="input-field col s6">
                                                            <p>Fecha Estimada</p>
                                                            <DatePicker
                                                            locale="es"
                                                            dateFormat="MMMM d, yyyy"
                                                            selected={fechaEstimada} onChange={handleChangeFechaEstimada} />
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="frecuencia" ref={node => {
                                                              frecuencia = node;
                                                          }} defaultValue={10} required/>
                                                          <label class="active" for="frecuencia">Frecuencia</label>
                                                          </div>

                                                          <div class="input-field col s12" style={{display : 'None'}}>
                                                          <p>Asigno</p>
                                                            <Select
                                                              //isMulti
                                                              name="actividades"
                                                              value={selectedAsigno}
                                                              onChange={handleChangeAsigno}
                                                              options={usuarioOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Estatus</p>
                                                            <Select
                                                              value={selectedEstatus}
                                                              onChange={handleChangeEstatus}
                                                              options={estatusOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12" style={{display : 'None'}}>
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="grado" ref={node => {
                                                              grado = node;
                                                          }} defaultValue={1} required/>
                                                          <label class="active" for="nombre">Grado</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="color" className="form-control" name="color" ref={node => {
                                                              color = node;
                                                          }} defaultValue={"#fff"} required/>
                                                          <label class="active" for="color">Color</label>
                                                          </div>

                                                          <div class="input-field col s12" style={{display : 'None'}}>
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="tipo" ref={node => {
                                                              tipo = node;
                                                          }}  />
                                                          <label class="active" for="tipo">tipo</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Categoria</p>
                                                            <Select
                                                              value={selectedTipoCategoria}
                                                              onChange={handleTipoCategoria}
                                                              options={tipoCategoriaOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                        

                                                        <div class="row">
                                                            <div class="row">
                                                            <div class="input-field col s12">
                                                            {!loading ? 
                                                              <button class="btn cyan waves-effect waves-light right"
                                                              type="submit" name="action">Enviar
                                                                <i class="material-icons right">send</i>
                                                              </button>
                                                            
                                                              :

                                                              <button class="btn btn-customizer cyan waves-effect waves-light right"
                                                              type="submit" name="action">Procesando
                                                                <i class="material-icons right">sync</i>
                                                              </button>
                                                            
                                                            }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                      }
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>
                            </div>
      </div>
  );
}

AsignacionTareasCreatComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default AsignacionTareasCreatComponent;
