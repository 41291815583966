import React from "react";
import { gql, useQuery } from '@apollo/client';
import LoadingComponent from "../../loading.component";

export const GET_USUARIOS = gql`
query {
  viewer {
    allUsuarios {
      edges {
        node {
          id
          nombre
          paterno
          materno
          email
          usuario
          username
          estatus
          createdAt
          roles{
            edges{
              node{
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
`;



export default function UsuariosPDFDinamico() {
  const { loading, data, error} = useQuery(GET_USUARIOS, 
    { fetchPolicy: "network-only",
    
    onError(error){
      console.log(error);

    }
  });



 return (
          <>
          <div class="col s12">
          <div class="container">
            
<section class="invoice-list-wrapper section">
<div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
  
  
  
  <div class="responsive-table">
    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer"><div class="top display-flex  mb-2"><div class="action-filters"><div id="DataTables_Table_0_filter" class="dataTables_filter"><label><input type="search" class="" placeholder="Search Invoice" aria-controls="DataTables_Table_0"/><div class="filter-btn">
    
    <a class="dropdown-trigger btn waves-effect waves-light purple darken-1 border-round" href="#" data-target="btn-filter">
      <span class="hide-on-small-only">Filtrar Usuario</span>
      <i class="material-icons">keyboard_arrow_down</i>
    </a><ul id="btn-filter" class="dropdown-content" tabindex="0">
      <li tabindex="0"><a href="#!">Paid</a></li>
      <li tabindex="0"><a href="#!">Unpaid</a></li>
      <li tabindex="0"><a href="#!">Partial Payment</a></li>
    </ul>
    
    
  </div></label></div></div><div class="actions action-btns display-flex align-items-center"><div class="invoice-filter-action mr-3">
    <a href="#" class="btn waves-effect waves-light invoice-export border-round z-depth-4">
      <i class="material-icons">picture_as_pdf</i>
      <span class="hide-on-small-only">Exportar a PDF</span>
    </a>
  </div><div class="invoice-create-btn">
    <a href="app-invoice-add.html" class="btn waves-effect waves-light invoice-create border-round z-depth-4">
      <i class="material-icons">add</i>
      <span class="hide-on-small-only">Crear Usuario</span>
    </a>
  </div></div></div><div class="clear"></div>
  
  {loading ? 
                                                <LoadingComponent /> 
                                                :
                  <>
                  <table class="table invoice-data-table white border-radius-4 pt-1 dataTable no-footer dtr-column" id="DataTables_Table_0" role="grid">
                  <thead>
                    <tr role="row"><th class="control sorting_disabled" rowspan="1" colspan="1" style={{ width: 8.16667 + 'px', display: 'none' }} aria-label=""></th>
                    <th class="dt-checkboxes-cell dt-checkboxes-select-all sorting_disabled" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{ width: 8.16667 + "px" }} data-col="1" aria-label="">
                      <input type="checkbox" />
                    </th>
                    <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{ width: 97.6167 }} aria-sort="ascending" aria-label="
            Invoice#
          : activate to sort column descending">
                      <span>Usuario</span>
                    </th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{ width: 100.933 }} aria-label="Amount: activate to sort column ascending">Nombre</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{ width: 68.5833 }} aria-label="Date: activate to sort column ascending">Paterno</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{ width: 183.5 }} aria-label="Customer: activate to sort column ascending">Materno</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{ width: 105.65 }} aria-label="Tags: activate to sort column ascending">Estatus</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{ width: 140.45 }} aria-label="Status: activate to sort column ascending">Rol</th>
                    <th class="sorting_disabled" rowspan="1" colspan="1" style={{ width: 65.9333 }} aria-label="Action">Acciones</th></tr>
                  </thead>

      <tbody>

      {
                                                    data.viewer.allUsuarios.edges.map(
                                                        record =>
        
        <tr role="row" class="odd" key = {record.node.id}>
                      <td class=" control" tabindex="0" style={{ display: 'none' }}></td>
                      <td class="dt-checkboxes-cell"><input type="checkbox" class="dt-checkboxes" /></td>
                      <td class="sorting_1">
                        <a href="app-invoice-view.html">{record.node.username}</a>
                      </td>
                      <td><span class="invoice-amount">{record.node.nombre}</span></td>
                      <td><span class="invoice-amount">{record.node.paterno}</span></td>
                      <td><span class="invoice-amount">{record.node.materno}</span></td>
                      <td>
                        <span class="bullet blue"></span>
                        <small>{record.node.estatus}</small>
                      </td>
                      <td>
                                                            {record.node.roles.edges.map(
                                                              role =>
                                                              <>
                                                              <span class="chip lighten-5 green green-text">{role.node.name}</span>
                                                              </>
                                                            )}
                        
                      
                      </td>
                      <td>
                        <div class="invoice-action">
                          <a href="app-invoice-view.html" class="invoice-action-view mr-4">
                            <i class="material-icons">remove_red_eye</i>
                          </a>
                          <a href="app-invoice-edit.html" class="invoice-action-edit">
                            <i class="material-icons">edit</i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    )
                  }
        
      </tbody>

    </table>
    <div class="bottom">
      <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
        <a class="paginate_button previous disabled" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="-1" id="DataTables_Table_0_previous">Previous</a><span><a class="paginate_button current" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0">1</a><a class="paginate_button " aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0">2</a></span><a class="paginate_button next" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" id="DataTables_Table_0_next">Next</a>
        </div></div>
        </>
    }

{error ? <div className="card-alert card red lighten-5">
                                                          <div className="card-content red-text">
                                                            <p>{`Error! ${error.message}`}</p>
                                                          </div>
                                                          <button type="button" className="close red-text" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                          </button>
                                                        </div> : null}
    </div>
  </div>
</section>
          </div>
          <div class="content-overlay"></div>
        </div>
    
    </>
  );
}
