import React, { useState } from "react";
import Axios from "axios";
import LoadingComponent from "../../loading.component";
import Select from 'react-select';

const estatusOptions = [
  { value: 'Todos', label: 'Todos' },
  { value: 'A', label: 'Alta' },
  { value: 'B', label: 'Baja' },
];

export default function MovieList() {;
  const [show, setHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedEstatus, setSelectedEstatus] = useState({ value: 'Todos', label: 'Todos' });

 
  const fetchMovie = async e => {
    
    setHide(false);
    setLoading(true);
    try {
      let res = await Axios.get(
        `https://apiwhipe.creasis.mx/user`
      );
      
      console.log(res.data);
      setLoading(false);
      setHide(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function handleChangeEstatus(selectedOption) {  
    setSelectedEstatus(selectedOption)
  }


  return (
          <>
          <div class="col s12">
          <div class="container">
            
<section class="invoice-list-wrapper section">
<div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
  
  
  
  <div class="responsive-table">
    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer">
      <div class="top display-flex  mb-2"><div class="action-filters">
        <div id="DataTables_Table_0_filter" class="dataTables_filter">
        <Select
          value={selectedEstatus}
          onChange={handleChangeEstatus}
          options={estatusOptions}
          classNamePrefix="Selecciona una opción"
          isSearchable={true}
        />
          
        </div>
        </div>
    <div class="actions action-btns display-flex align-items-center"><div class="invoice-filter-action mr-3">
    <a href="#" onClick={fetchMovie} class="btn waves-light invoice-export border-round z-depth-4">
      <i class="material-icons">picture_as_pdf</i>
      <span class="hide-on-small-only">Exportar a PDF</span>
    </a>
  </div>
  
  </div></div><div class="clear"></div>

  <div class="col s12">
        <div class="container">
          
          <div class="card">
            <div class="card-content">
              <h4 class="card-title">Reporte  de usuarios</h4>
              <div class="row">
                <div class="row">
                {loading && (
                  <>
                  <LoadingComponent /> 
                  </>
                )
                }

                {show && (
                <>

                <object style={{width:100 +'%', height: 800}} data="https://whip.creasis.mx/reports/whip_usuarios.pdf" type="application/pdf">
                    <iframe style={{width:100 +'%', height: 800}} src="https://docs.google.com/viewer?url=https://whip.creasis.mx/reports/whip_usuarios.pdf&embedded=true"></iframe>
                </object>

                
                
                </>

                    
                  )}
                  
                </div>
              </div>
            </div>
          </div>

          
          
        </div>
        <div class="content-overlay"></div>
      </div>

  
  
    
    </div>
  </div>
</section>
          </div>
          <div class="content-overlay"></div>
        </div>
    
    </>
  );
}
