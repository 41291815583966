import React, { Component, useState } from "react";
import LoadingComponent from "../../loading.component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Select from 'react-select';


const GET_CONTACTO = gql`
query contacto($contactoId: String) {
  viewer {
    contacto(contactoId: $contactoId) {
      id
      codigo
      usuario
      usuarioData{
        id
        username
        nombre
        paterno
        materno
      }
      tipo
      tipoData{
        id
        codigo
        nombre
      }
      nombre
      paterno
      materno
      telefono
      celular
      correo
      domicilio
      ciudad
      estado
      pais
      cp
      sexo
      estatus
    }
    allTipoContactos {
      edges {
        node {
          id
          codigo
          nombre
        }
      }
    }
    allUsuarios {
      edges {
        node {
          id
          username
          nombre
          paterno
          materno
        }
      }
    }
  }
}
`;
const UPDATE_CONTACTO = gql`
mutation UpdateContactoMutation($contactoData:ContactosInput!){
  updateContacto(
    contactoData: $contactoData
  )
  {
  contacto{
    id
    nombre
  }  
  }
}
`;

const estatusOptions = [
  { value: 'A', label: 'Alta' },
  { value: 'B', label: 'Baja' }
];

function ContactosEditComponent(contacto) {

  let contactoId = contacto.match.params.id;
  const [selectedTipoContacto, setTipoContacto] = useState(null);
  const [tiposContactoOptions, setTiposContactoOptions] = useState([]);
  const [selectedUsuario, setUsuario] = useState(null);
  const [usuarioOptions, setUsuarioOptions] = useState([]);
  const [sexo, setSexo] = useState('H');
  const [selectedEstatus, setSelectedEstatus] = useState("");
  

  const history = useHistory();
  const { loading: requsitoLoading, error: contactoError, data: contactoData } = useQuery(GET_CONTACTO, {
    variables: { contactoId },
    onCompleted(data){
      const tiposContacto = [...data.viewer.allTipoContactos.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )

      const usuarios = [...data.viewer.allUsuarios.edges].map(o => 
        ({ value: o.node.username, label: `${o.node.nombre} ${o.node.paterno} ${o.node.materno}` })
      )
      
      if(data.viewer.contacto.usuarioData){
      setUsuario({ value: data.viewer.contacto.usuarioData.username,
        label: `${data.viewer.contacto.usuarioData.nombre} ${data.viewer.contacto.usuarioData.paterno} ${data.viewer.contacto.usuarioData.materno}` });
      }
      if(data.viewer.contacto.tipoData){
      setTipoContacto({ value: data.viewer.contacto.tipoData.codigo,
        label: data.viewer.contacto.tipoData.nombre });
      }

      setSexo(data.viewer.contacto.sexo);
      //setSelectedEstatus(data.viewer.contacto.estatus);
      setUsuarioOptions(usuarios);
      setTiposContactoOptions(tiposContacto);

      switch (data.viewer.contacto.estatus) {
        case 'A':
          return setSelectedEstatus({ value: 'A', label: 'Alta' });
        case 'B':
          return setSelectedEstatus({ value: 'B', label: 'Baja' });
    }
      

    }
  }); 

    

  const [updateContacto, { data, loading, error, reset }] = useMutation(UPDATE_CONTACTO,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted(){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Contacto actualizado`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });

        history.push({
          pathname:  `/catalogos/contactos`,
          state: {
            response: 'success' 
          } 
        });
        
      }
      
    }
    );

  let codigo, nombre, paterno, materno, telefono, celular, correo,
   domicilio, ciudad, estado, pais, cp, estatus;

  function handleChangeTipoContacto(selectedOption) {
    setTipoContacto(selectedOption)
  }

  function handleChangeUsuario(selectedOption) {
    setUsuario(selectedOption)
  }

  const handleChangeSexo = (event) => {
    setSexo(event.target.value)
  }

  function handleChangeEstatus(selectedOption) {  
    setSelectedEstatus(selectedOption)
  }

  //
  //if (requsitoLoading) return 'Loading...';   
  if (contactoError) return `Error! ${contactoError.message}`;
  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Editar Contactos</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">Contactos
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabindex="0">
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabindex="-1"></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div class="card-content">


          <h4 class="card-title">Catálogo de Contactos</h4>
                                                {requsitoLoading ? 
                                                <LoadingComponent /> 
                                                :

          
                                                      <form onSubmit={e => {
                                                              e.preventDefault();
                                                              updateContacto({ variables: { contactoData : { id: contactoData.viewer.contacto.id,
                                                                codigo: codigo.value, nombre: nombre.value, paterno: paterno.value,
                                                                materno: materno.value, telefono: telefono.value, celular: celular.value,
                                                                correo: correo.value, domicilio: domicilio.value, ciudad: ciudad.value, 
                                                                estado: estado.value, pais: pais.value, cp: cp.value, sexo: sexo,
                                                                estatus: selectedEstatus ? selectedEstatus.value : null,
                                                                usuario: selectedUsuario ? selectedUsuario.value : null,
                                                                email: correo.value, tipo: selectedTipoContacto ? selectedTipoContacto.value : null}} });
                                                              
                                                          }}>
                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">code</i>
                                                          <input disabled type="text" className="form-control" name="codigo" ref={node => {
                                                              codigo = node;
                                                          }} defaultValue={contactoData.viewer.contacto.codigo} />                                                          
                                                          <label class="active" for="codigo">Código</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Usuario</p>
                                                            <Select
                                                              value={selectedUsuario}
                                                              onChange={handleChangeUsuario}
                                                              options={usuarioOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Tipo Contacto</p>
                                                            <Select
                                                              value={selectedTipoContacto}
                                                              onChange={handleChangeTipoContacto}
                                                              options={tiposContactoOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                            />
                                                          </div>

                                                          <div class="input-field col s4">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="nombre" ref={node => {
                                                              nombre = node;
                                                          }} defaultValue={contactoData.viewer.contacto.nombre} required/>
                                                          <label class="active" for="nombre">Nombre</label>
                                                          </div>

                                                          <div className="input-field col s4">
                                                          <i className="material-icons prefix">portrait</i>
                                                          <input id="paterno"  name="paterno" ref={node => {
                                                                                  paterno = node;
                                                                              }} defaultValue={contactoData.viewer.contacto.paterno}
                                                            type="text"/>
                                                            <label className="active" for="paterno">Paterno</label>
                                                          </div>
                                                      
                                                          <div className="input-field col s4">
                                                            <i className="material-icons prefix">portrait</i>
                                                            <input id="materno"  name="materno" ref={node => {
                                                                                    materno = node;
                                                                                }} defaultValue={contactoData.viewer.contacto.materno}
                                                            type="text"/>
                                                            <label className="active" for="materno">Materno</label>
                                                          </div>

                                                          <div className="input-field col s4">
                                                            <i className="material-icons prefix">contact_phone</i>
                                                            <input id="telefono"  name="telefono" ref={node => {
                                                                                    telefono = node;
                                                                                }} defaultValue={contactoData.viewer.contacto.telefono}
                                                            type="text"/>
                                                            <label className="active" for="telefono">Teléfono</label>
                                                          </div>

                                                          <div className="input-field col s4">
                                                            <i className="material-icons prefix">phone_iphone</i>
                                                            <input id="celular"  name="celular" ref={node => {
                                                                                    celular = node;
                                                                                }} defaultValue={contactoData.viewer.contacto.celular}
                                                            type="text"/>
                                                            <label className="active" for="celular">Celular</label>
                                                          </div>

                                                          <div className="input-field col s4">
                                                            <i className="material-icons prefix">contact_mail</i>
                                                            <input id="correo"  name="correo" ref={node => {
                                                                                    correo = node;
                                                                                }} defaultValue={contactoData.viewer.contacto.correo}
                                                            type="text"/>
                                                            <label className="active" for="correo">Correo electrónico</label>
                                                          </div>

                                                          <div className="input-field col s12">
                                                            <i className="material-icons prefix">place</i>
                                                            <input id="domicilio"  name="domicilio" ref={node => {
                                                                                    domicilio = node;
                                                                                }} defaultValue={contactoData.viewer.contacto.domicilio}
                                                            type="text"/>
                                                            <label className="active" for="domicilio">Domicilio</label>
                                                          </div>

                                                          <div className="input-field col s4">
                                                            <i className="material-icons prefix">place</i>
                                                            <input id="ciudad"  name="ciudad" ref={node => {
                                                                                    ciudad = node;
                                                                                }} defaultValue={contactoData.viewer.contacto.ciudad}
                                                            type="text"/>
                                                            <label className="active" for="ciudad">Ciudad</label>
                                                          </div>

                                                          <div className="input-field col s4">
                                                            <i className="material-icons prefix">place</i>
                                                            <input id="estado"  name="estado" ref={node => {
                                                                                    estado = node;
                                                                                }} defaultValue={contactoData.viewer.contacto.estado}
                                                            type="text"/>
                                                            <label className="active" for="estado">Estado</label>
                                                          </div>

                                                          <div className="input-field col s4">
                                                            <i className="material-icons prefix">place</i>
                                                            <input id="pais"  name="pais" ref={node => {
                                                                                    pais = node;
                                                                                }} defaultValue={contactoData.viewer.contacto.pais}
                                                            type="text"/>
                                                            <label className="active" for="pais">País</label>
                                                          </div>

                                                          <div className="input-field col s4">
                                                            <i className="material-icons prefix">place</i>
                                                            <input id="cp"  name="cp" ref={node => {
                                                                                    cp = node;
                                                                                }} defaultValue={contactoData.viewer.contacto.cp}
                                                            type="text"/>
                                                            <label className="active" for="cp">Código Postal</label>
                                                          </div>

                                                          <div className="col s4">

                                                      <p>Sexo</p>
                                                      <p>
                                                        <label>
                                                          <input class="validate"
                                                            required name="gender0"
                                                            type="radio"
                                                            value="H"
                                                            checked={sexo === 'H'}
                                                            onChange={handleChangeSexo} />
                                                          <span>Hombre</span>
                                                        </label>
                                                      </p>

                                                      <label>
                                                        <input class="validate"
                                                          required name="gender0"
                                                          type="radio"
                                                          value="M"
                                                          checked={sexo === 'M'}
                                                          onChange={handleChangeSexo} />
                                                        <span>Mujer</span>
                                                      </label>
                                                      <div class="input-field">
                                                      </div>
                                                    </div>

                                                    <div className="input-field col s4">
                                                      <p>Estatus</p>
                                                      <Select
                                                        value={selectedEstatus}
                                                        onChange={handleChangeEstatus}
                                                        options={estatusOptions}
                                                        classNamePrefix="Selecciona una opción"
                                                        name="estatus"
                                                      />
                                                    </div>

                                                        

                                                        <div class="row">
                                                            <div class="row">
                                                            <div class="input-field col s12">
                                                            {!loading ? 
                                                              <button class="btn cyan waves-effect waves-light right"
                                                              type="submit" name="action">Enviar
                                                                <i class="material-icons right">send</i>
                                                              </button>
                                                            
                                                              :

                                                              <button class="btn btn-customizer cyan waves-effect waves-light right"
                                                              type="submit" name="action">Procesando
                                                                <i class="material-icons right">sync</i>
                                                              </button>
                                                            
                                                            }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                      }
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>
                            </div>
      </div>
  );
}

ContactosEditComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default ContactosEditComponent;
