import React, { Component, useState } from "react";
import LoadingComponent from "../../loading.component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import DateTimePicker from 'react-datetime-picker';


const GET_ASIGNACIONACTO = gql`
query asignacionacto($asignacionactoId: String) {
  viewer {
    asignacionacto(asignacionactoId: $asignacionactoId) {
      id
      codigo
      acto
      subDesarrollo
      subdesarrolloAsignado{
        id
        codigo
        nombre
      }
      asesor
      asesorAsignado{
        id
        username
        nombre
        paterno
        materno
      }
      actoAsignado{
        id
        codigo
        nombre
      }
      acto
      fechaAsignacion
      fechaEstimada
      estatus
      grado
      color
      tipo
      createdAt    
    }
    allActos {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allUsuarios {
      edges {
        node {
          id
          username
          nombre
          paterno
          materno
        }
      }
    }
    allActos {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allSubDesarrollos {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
  }
}
`;
const UPDATE_ASIGNACIONACTO = gql`
mutation UpdateAsignacionActoMutation($asignacionactoData:AsignacionActosInput!){
  updateAsignacionActo(
    asignacionactoData: $asignacionactoData
  )
  {
  asignacionacto{
    id
  }  
  }
}
`;
const estatusOptions = [
  { value: 'P', label: 'En Proceso' },
  { value: 'E', label: 'Pendiente' },
  { value: 'T', label: 'Terminada' },
  { value: 'C', label: 'Cancelada' },
];


function AsignacionActosEditComponent(asignacionacto) {

  let asignacionactoId = asignacionacto.match.params.id;
  registerLocale('es', es)  

  const [selectedAsigno, setAsigno] = useState(null);
  const [selectedAsesor, setAsesor] = useState(null);

  const [usuarioOptions, setUsuarioOptions] = useState([]);

  const [selectedActo, setActo] = useState(null);
  const [actoOptions, setActoOptions] = useState([]);

  const [selectedSubDesarollo, setSubDesarollo] = useState(null);
  const [subDesarolloOptions, setSubDesarolloOptions] = useState([]);

  const [startDate, setStartDate] = useState(new Date());

  const [fechaAsignacion, setFechaAsignacion] = useState(new Date());
  const [fechaEstimada, setFechaEstimada] = useState(new Date());

  const [selectedEstatus, setEstatus] = useState(null);

  
  const [value, onChange] = useState(new Date());

  const history = useHistory();
  const { loading: requsitoLoading, error: asignacionactoError, data: asignacionactoData } = useQuery(GET_ASIGNACIONACTO, {
    variables: { asignacionactoId },
    onCompleted(data){
      //const defaultActos = [...data.viewer.asignacionacto.actos.edges].map(o => 
        //({ value: o.node.codigo, label: o.node.nombre })
      //)
      //setActo(defaultActos) 
      setFechaAsignacion(new Date(data.viewer.asignacionacto.fechaAsignacion));
      setFechaEstimada(new Date(data.viewer.asignacionacto.fechaEstimada));

      const actos = [...data.viewer.allActos.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )
      setActoOptions(actos);

      const subDesarrollos = [...data.viewer.allSubDesarrollos.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )

      setSubDesarolloOptions(subDesarrollos);

      const usuarios = [...data.viewer.allUsuarios.edges].map(o => 
        ({ value: o.node.username, label: `${o.node.nombre} ${o.node.paterno} ${o.node.materno}` })
      )
      setUsuarioOptions(usuarios);

      setActo({ value: data.viewer.asignacionacto.actoAsignado.codigo, label: data.viewer.asignacionacto.actoAsignado.nombre })
      
      setSubDesarollo({ value: data.viewer.asignacionacto.subdesarrolloAsignado.codigo, label: data.viewer.asignacionacto.subdesarrolloAsignado.nombre })
      
      setAsesor({ value: data.viewer.asignacionacto.asesorAsignado.username,
        label: `${data.viewer.asignacionacto.asesorAsignado.nombre} ${data.viewer.asignacionacto.asesorAsignado.paterno} ${data.viewer.asignacionacto.asesorAsignado.materno}` });

      
      //setEstatus({ value: 'P', label: 'En Proceso' });
      switch (data.viewer.asignacionacto.estatus) {
          case 'P':
            return setEstatus({ value: 'P', label: 'En Proceso' });
          case 'E':
            return setEstatus({ value: 'E', label: 'Pendiente' });
          case 'T':
            return setEstatus({ value: 'T', label: 'Terminada' });
          case 'C':
            return setEstatus({ value: 'C', label: 'Cancelada' });
      }
    }
  }); 

    

  const [updateAsignacionActo, { data, loading, error, reset }] = useMutation(UPDATE_ASIGNACIONACTO,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted(){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `AsignacionActo actualizado`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });

        history.push({
          pathname:  `/procesos/asignacionactos`,
          state: {
          response: 'success' 
          } 
        });
        
      }
      
    }
    );
  let codigo, color, grado, tipo;

  function handleChangeActo(selectedOption) {  
    setActo(selectedOption)
  }

  function handleChangeAsesor(selectedOption) {  
    setAsesor(selectedOption)
  }

  function handleChangeActo(selectedOption) {  
    setActo(selectedOption)
  }

  function handleChangeAsigno(selectedOption) {  
    setAsigno(selectedOption)
  }

  function handleChangeFechaAsignacion(selectedDate) {  
    setFechaAsignacion(selectedDate)
  }

  function handleChangeFechaEstimada(selectedDate) {  
    setFechaEstimada(selectedDate)
  }

  function handleChangeEstatus(selectedOption) {  
    setEstatus(selectedOption)
  }
  
  function handleSubDesarollo(selectedOption) {  
    setSubDesarollo(selectedOption)
  }
  //
  //if (requsitoLoading) return 'Loading...';   
  if (asignacionactoError) return `Error! ${asignacionactoError.message}`;
  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Editar AsignacionActos</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">AsignacionActos
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabindex="0">
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabindex="-1"></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div class="card-content">


          <h4 class="card-title">Catálogo de AsignacionActos</h4>
                                                {requsitoLoading ? 
                                                <LoadingComponent /> 
                                                :

          
                                                      <form onSubmit={e => {
                                                              e.preventDefault();
                                                              updateAsignacionActo({ variables: { asignacionactoData : {
                                                                 id: asignacionactoData.viewer.asignacionacto.id, codigo: codigo.value,
                                                                acto: selectedActo ? selectedActo.value : null,
                                                                subDesarrollo : selectedSubDesarollo ? selectedSubDesarollo.value : null,
                                                                asesor : selectedAsesor ? selectedAsesor.value : null,
                                                                grado : grado.value, color : color.value, tipo: tipo.value,
                                                                estatus: selectedEstatus ? selectedEstatus.value : null,
                                                                fechaAsignacion: fechaAsignacion, fechaEstimada: fechaEstimada }} });
                                                              
                                                          }}>
                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">code</i>
                                                          <input disabled type="text" className="form-control" name="codigo" ref={node => {
                                                              codigo = node; 
                                                          }} defaultValue={asignacionactoData.viewer.asignacionacto.codigo} />

                                                          
                                                          <label class="active" for="codigo">Código</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Acto</p>
                                                            <Select
                                                              value={selectedActo}
                                                              onChange={handleChangeActo}
                                                              options={actoOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Sub Desarrollo</p>
                                                            <Select
                                                              value={selectedSubDesarollo}
                                                              onChange={handleSubDesarollo}
                                                              options={subDesarolloOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Asesor</p>
                                                            <Select
                                                              value={selectedAsesor}
                                                              onChange={handleChangeAsesor}
                                                              options={usuarioOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s6">
                                                            <p>Fecha de Asignación</p>
                                                            <DatePicker
                                                             //showTimeSelect
                                                             //dateFormat="Pp"
                                                             locale="es"
                                                             dateFormat="MMMM d, yyyy"
                                                             selected={fechaAsignacion} onChange={handleChangeFechaAsignacion} />
                                                          </div>

                                                          <div class="input-field col s6">
                                                            <p>Fecha Estimada</p>
                                                            <DatePicker
                                                            locale="es"
                                                            dateFormat="MMMM d, yyyy"
                                                            selected={fechaEstimada} onChange={handleChangeFechaEstimada} />
                                                          </div>                                                       

                                                          

                                                          <div class="input-field col s12">
                                                            <p>Estatus</p>
                                                            <Select
                                                              value={selectedEstatus}
                                                              onChange={handleChangeEstatus}
                                                              options={estatusOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="frecuencia" ref={node => {
                                                              grado = node;
                                                          }} defaultValue={asignacionactoData.viewer.asignacionacto.grado} required/>
                                                          <label class="active" for="grado">Grado</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="color" className="form-control" name="color" ref={node => {
                                                              color = node;
                                                          }} defaultValue={asignacionactoData.viewer.asignacionacto.color} required/>
                                                          <label class="active" for="color">Color</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="tipo" ref={node => {
                                                              tipo = node;
                                                          }} defaultValue={asignacionactoData.viewer.asignacionacto.tipo} required/>
                                                          <label class="active" for="tipo">Tipo</label>
                                                          </div>

                                                        

                                                        <div class="row">
                                                            <div class="row">
                                                            <div class="input-field col s12">
                                                            {!loading ? 
                                                              <button class="btn cyan waves-effect waves-light right"
                                                              type="submit" name="action">Enviar
                                                                <i class="material-icons right">send</i>
                                                              </button>
                                                            
                                                              :

                                                              <button class="btn btn-customizer cyan waves-effect waves-light right"
                                                              type="submit" name="action">Procesando
                                                                <i class="material-icons right">sync</i>
                                                              </button>
                                                            
                                                            }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                      }
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>
                            </div>
      </div>
  );
}

AsignacionActosEditComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default AsignacionActosEditComponent;
