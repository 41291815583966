import React, { useState, useEffect }  from "react";
import 'moment/locale/es'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Select from 'react-select';


const CREATE_SUBDESARROLLO = gql`
mutation CreateSubDesarrolloMutation($subdesarrolloData:SubDesarrollosInput!){
  createSubDesarrollo(
    subdesarrolloData: $subdesarrolloData
  )
  {
  subdesarrollo{
    id
    nombre
  }  
  }
}
`;

export const GET_DATA = gql`
query {
  viewer {
    allDesarrollos {
      edges {
        node {
          codigo
          nombre
        }
      }
    }
    allContactos {
      edges {
        node {
          codigo
          nombre
          paterno
          materno
        }
      }
    }
  }
}
`;

function SubDesarrollosCreateComponent() {
  const history = useHistory();
  const [selectedDesarrollo, setSelectedDesarrollo] = useState("");
  const [selectedOption, setselectedOption] = useState(null);
  const [selectOptions, setselectOptions] = useState([]);
  const [selectedCliente, setCliente] = useState(null);
  const [selectedContacto, setContacto] = useState(null);
  const [contactoOptions, setContactoOptions] = useState([]);

  const { loading: requsitoLoading, error: subdesarrolloError, data: subdesarrolloData } = useQuery(GET_DATA, {
    
    onCompleted(data){
      const selectedOptions = [...data.viewer.allDesarrollos.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )

      setselectOptions(selectedOptions);
      const contactos = [...data.viewer.allContactos.edges].map(o => 
        ({ value: o.node.codigo, label: `${o.node.nombre} ${o.node.paterno} ${o.node.materno}` })
      )
      setContactoOptions(contactos)
      
    }
  });

  const [createSubDesarrollo, { data, loading, error, reset }] = useMutation(CREATE_SUBDESARROLLO,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted(){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `SubDesarrollo añadido`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });

        history.push({
          pathname:  `/catalogos/subdesarrollos`,
          state: {
            response: 'succes' 
          } 
        });
        
      }
      
    }
    );
  let codigo, nombre, domicilio;

  function handleChange(selectedOption) {
    setselectedOption(selectedOption)
  }

  function handleChangeContacto(selectedOption) {
    setContacto(selectedOption)
  }

  function handleChangeCliente(selectedOption) {  
    setCliente(selectedOption)
  }

  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Editar SubDesarrollos</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">SubDesarrollos
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabindex="0">
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabindex="-1"></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div class="card-content">


          <h4 class="card-title">Catálogo de SubDesarrollos</h4>

          
                                                      <form onSubmit={e => {
                                                              e.preventDefault();
                                                              createSubDesarrollo({ variables: { subdesarrolloData : { codigo: codigo.value, nombre: nombre.value, domicilio: domicilio.value,
                                                                desarrollo: selectedOption.value, contacto: selectedContacto.value,
                                                                cliente: selectedCliente.value }} });
                                                          }}>
                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">code</i>
                                                          <input disabled type="text" className="form-control" name="codigo" ref={node => {
                                                              codigo = node;
                                                          }}/>

                                                          
                                                          <label class="active" for="codigo">Código</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="nombre" ref={node => {
                                                              nombre = node;
                                                          }} required/>
                                                          <label class="active" for="nombre">SubDesarrollo</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <p>Desarrollo</p>
                                                            <Select
                                                              value={selectedOption}
                                                              onChange={handleChange}
                                                              options={selectOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                            />

                                                          </div>

                                                          <div class="input-field col s12">
                                                          <p>Cliente</p>
                                                            <Select
                                                              value={selectedCliente}
                                                              onChange={handleChangeCliente}
                                                              options={contactoOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />

                                                          </div>

                                                          <div class="input-field col s12">
                                                          <p>Contacto</p>
                                                            <Select
                                                              value={selectedContacto}
                                                              onChange={handleChangeContacto}
                                                              options={contactoOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                            />

                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">place</i>
                                                          <input type="text" className="form-control" name="domicilio" ref={node => {
                                                              domicilio = node;
                                                          }} required/>
                                                          <label class="active" for="domicilio">Domicilio</label>
                                                          </div>

                                                        

                                                        <div class="row">
                                                            <div class="row">
                                                            <div class="input-field col s12">
                                                            {!loading ? 
                                                              <button class="btn cyan waves-effect waves-light right"
                                                              type="submit" name="action">Enviar
                                                                <i class="material-icons right">send</i>
                                                              </button>
                                                            
                                                              :

                                                              <button class="btn btn-customizer cyan waves-effect waves-light right"
                                                              type="submit" name="action">Procesando
                                                                <i class="material-icons right">sync</i>
                                                              </button>
                                                            
                                                            }
                                                              
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>
                            </div>
      </div>
  );
}

SubDesarrollosCreateComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default SubDesarrollosCreateComponent;
