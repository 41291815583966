import React, { useState, useEffect } from "react";
import LoadingComponent from "../../loading.component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { gql, useMutation, useQuery} from '@apollo/client';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import DateTimePicker from 'react-datetime-picker';
import Moment from 'moment';
import { Scrollbar } from "react-scrollbars-custom";
import {getRandomColor,createImageFromInitials} from '../../Utils';
import io from "socket.io-client";


const GET_ASIGNACIONACTO = gql`
query asignacionactividad($asignacionactividadId: String) {
  viewer {
    asignacionactividad(asignacionactividadId: $asignacionactividadId) {
      id
      codigo
      actividad
      subDesarrollo
      subdesarrolloAsignado{
        id
        codigo
        nombre
      }
      asesor
      asesorAsignado{
        id
        username
        nombre
        paterno
        materno
      }
      actividadAsignado{
        id
        codigo
        nombre
      }
      tipoCategoria{
        id
        nombre
      }
      actividad
      fechaAsignacion
      fechaEstimada
      estatus
      grado
      color
      tipo
      createdAt
      asignacionTareas {
        edges {
          node {
            id
            codigo
            actividad
            comentarios {
              edges {
                node {
                  id
                  comentario
                  comento
                  comentarioPor {
                    id
                    nombre
                    paterno
                    materno
                  }
                  createdAt
                  updatedAt
                }
              }
            }
            actividadAsignado {
              id
              codigo
              actividadAsignado {
                id
                codigo
                nombre
              }
            }
            ejecutor
            tarea
            tareaAsignada {
              id
              codigo
              nombre
            }
            asigno
            asignadoPor {
              id
              username
              nombre
              paterno
              materno
            }
            ejecutorAsignado {
              id
              username
              nombre
              paterno
              materno
            }
            fechaAsignacion
            fechaEstimada
            frecuencia
            estatus
            grado
            color
            tipo
            createdAt
          }
        }
      }
    }
    allActividades {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allUsuarios {
      edges {
        node {
          id
          username
          nombre
          paterno
          materno
        }
      }
    }
    allActividades {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allSubDesarrollos {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
    allTipoCategorias {
      edges {
        node {
          id
          nombre
          createdAt
          updatedAt
        }
      }
    }
    allTareas {
      edges {
        node {
          id
          codigo
          nombre
          createdAt
        }
      }
    }
  }
}
`;
const UPDATE_ASIGNACIONACTO = gql`
mutation UpdateAsignacionActividadMutation($asignacionactividadData:AsignacionActividadesInput!){
  updateAsignacionActividad(
    asignacionactividadData: $asignacionactividadData
  )
  {
  asignacionactividad{
    id
  }  
  }
}
`;

const UPDATE_ASIGNACIONTAREA = gql`
mutation UpdateAsignacionTareaMutation($asignaciontareaData:AsignacionTareasInput!){
  updateAsignacionTarea(
    asignaciontareaData: $asignaciontareaData
  )
  {
  asignaciontarea{
    id
  }  
  }
}
`;

const CREATE_COMENTARIO_TAREA = gql`
mutation CreateAsignacionComentarioMutation($asignacioncomentarioData:AsignacionComentariosInput!){
  createAsignacionComentario(
    asignacioncomentarioData: $asignacioncomentarioData
  )
  {
  asignacioncomentario{
    id
  }  
  }
}
`;

const CREATE_ASIGNACIONTAREA = gql`
mutation CreateAsignacionTareaMutation($asignaciontareaData:AsignacionTareasInput!){
  createAsignacionTarea(
    asignaciontareaData: $asignaciontareaData
  )
  {
  asignaciontarea{
    id
  }  
  }
}
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    zIndex: -1
  }),
}

const estatusOptions = [
  { value: 'P', label: 'En Proceso' },
  { value: 'E', label: 'Pendiente' },
  { value: 'T', label: 'Terminada' },
  { value: 'C', label: 'Cancelada' },
];

const estatusOptionsTarea = [
  { value: 'P', label: 'En Proceso' },
  { value: 'E', label: 'Pendiente' },
  { value: 'T', label: 'Terminada' },
  { value: 'C', label: 'Cancelada' },
];



function AsignacionActividadesEditComponent(asignacionactividad) {

  let asignacionactividadId = asignacionactividad.match.params.id;
  registerLocale('es', es)
  const [socket, setSocket] = useState(null);
  const ENDPOINT = "https://apiwhipe.creasis.mx";
  const [selectedAsigno, setAsigno] = useState(null);
  const [selectedAsesor, setAsesor] = useState(null);

  const [usuarioOptions, setUsuarioOptions] = useState([]);

  const [selectedActividad, setActividad] = useState(null);
  const [actividadOptions, setActividadOptions] = useState([]);

  const [selectedTipoCategoria, setTipoCategoria] = useState(null);
  const [tipoCategoriaOptions, setTipoCategoriaOptions] = useState([]);

  const [selectedSubDesarollo, setSubDesarollo] = useState(null);
  const [subDesarolloOptions, setSubDesarolloOptions] = useState([]);

  const [startDate, setStartDate] = useState(new Date());

  const [fechaAsignacion, setFechaAsignacion] = useState(new Date());
  const [fechaEstimada, setFechaEstimada] = useState(new Date());

  const [selectedEstatus, setEstatus] = useState(null);
  
  const [showSidebar, setshowSidebar] = useState(null);
  const [showSidebarActividad, setshowSidebarActividad] = useState(null);
  registerLocale('es', es)
  const [idTarea, setIdTarea] = useState(null);
  const [codigoTarea, setCodigotarea] = useState(null);
  const [gradoTarea, setGradoTarea] = useState(null);
  const [colorTarea, setColorTarea] = useState(null);
  const [tipoTarea, setTipoTarea] = useState(null);
  const [selectedEjecutorTarea, setEjecutorTarea] = useState(null);
  const [selectedTarea, setTarea] = useState(null);
  const [tareaOptions, setTareaOptions] = useState([]);
  const [frecuenciaTarea, setFrecuenciaTarea] = useState(null);
  const [selectedComentarios, setComentarios] = useState([]);
  const [comentariosOptions, setComentariosOptions] = useState([]);

  const [comentarioTarea, setComentarioTarea] = useState(null);
  const [comentariosRealizadosTarea, setComentariosRealizadosTarea] = useState([]);

  const [selectedActividadTarea, setActividadTarea] = useState(null);
  const [fechaAsignacionTarea, setFechaAsignacionTarea] = useState(new Date());
  const [fechaEstimadaTarea, setFechaEstimadaTarea] = useState(new Date());
  const [selectedAsignoTarea, setAsignoTarea] = useState(null);
  const [selectedEjecutor, setEjecutor] = useState(null);
  const [selectedEstatusTarea, setEstatusTarea] = useState(null);
  const [actividadOptionsTarea, setActividadOptionsTarea] = useState([]);
  const [selectedTareaTarea, setTareaTarea] = useState(null);
  const [tareaOptionsTarea, setTareaOptionsTarea] = useState([]);

  const [value, onChange] = useState(new Date());
  const [actionUpdateOrCreate, setactionUpdateOrCreate] = useState(true);  

  const history = useHistory();
  const { loading: requsitoLoading, error: asignacionactividadError, data: asignacionactividadData, refetch } = useQuery(GET_ASIGNACIONACTO, {
    variables: { asignacionactividadId },
    onCompleted(data){
      //const defaultActividades = [...data.viewer.asignacionactividad.actividades.edges].map(o => 
        //({ value: o.node.codigo, label: o.node.nombre })
      //)
      //setActividad(defaultActividades) 
      setFechaAsignacion(new Date(data.viewer.asignacionactividad.fechaAsignacion));
      setFechaEstimada(new Date(data.viewer.asignacionactividad.fechaEstimada));

      const actividades = [...data.viewer.allActividades.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )
      setActividadOptions(actividades);

      const subDesarrollos = [...data.viewer.allSubDesarrollos.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )

      setSubDesarolloOptions(subDesarrollos);

      const usuarios = [...data.viewer.allUsuarios.edges].map(o => 
        ({ value: o.node.username, label: `${o.node.nombre} ${o.node.paterno} ${o.node.materno}` })
      )
      setUsuarioOptions(usuarios);

      setActividad({ value: data.viewer.asignacionactividad.actividadAsignado.codigo, label: data.viewer.asignacionactividad.actividadAsignado.nombre })
      setActividadTarea({ value: data.viewer.asignacionactividad.codigo, label: data.viewer.asignacionactividad.actividadAsignado.nombre })

      console.log('setActividadTarea', data.viewer.asignacionactividad.codigo);


      setSubDesarollo({ value: data.viewer.asignacionactividad.subdesarrolloAsignado.codigo, label: data.viewer.asignacionactividad.subdesarrolloAsignado.nombre })
      
      setAsesor({ value: data.viewer.asignacionactividad.asesorAsignado.username,
        label: `${data.viewer.asignacionactividad.asesorAsignado.nombre} ${data.viewer.asignacionactividad.asesorAsignado.paterno} ${data.viewer.asignacionactividad.asesorAsignado.materno}` });
      
      const tipoCategorias = [...data.viewer.allTipoCategorias.edges].map(o => 
          ({ value: o.node.id, label: o.node.nombre })
      )

      setTipoCategoriaOptions(tipoCategorias);

      setTipoCategoria({ value: data.viewer.asignacionactividad.tipoCategoria.id, label: data.viewer.asignacionactividad.tipoCategoria.nombre })

      const tareas = [...data.viewer.allTareas.edges].map(o => 
        ({ value: o.node.codigo, label: o.node.nombre })
      )

      //const labelActo = `${data.viewer.asignacionactividad.actividad} - ${data.viewer.asignacionactividad.actividadAsignado.codigo}[${data.viewer.asignacionactividad.actividadAsignado.nombre}]`
      //setActividadTarea({ value: data.viewer.asignacionactividad, label: labelActo });


      setTareaOptionsTarea(tareas);
      //setEstatus({ value: 'P', label: 'En Proceso' });
      switch (data.viewer.asignacionactividad.estatus) {
          case 'P':
            return setEstatus({ value: 'P', label: 'En Proceso' });
          case 'E':
            return setEstatus({ value: 'E', label: 'Pendiente' });
          case 'T':
            return setEstatus({ value: 'T', label: 'Terminada' });
          case 'C':
            return setEstatus({ value: 'C', label: 'Cancelada' });
      }

      
    }
  });
  
  const [createAsignacionTarea, { data: createAsignacionTareaData,
     loading : createAsignacionTareaLoading, error : createAsignacionTareaError,
      reset : createAsignacionTareaReset }] = useMutation(CREATE_ASIGNACIONTAREA,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();
      },
      onCompleted(){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `AsignacionTarea actualizado`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        handleRefetchActividades();
        setshowSidebar(null);
        socket.emit('new-message-s', data);
      }      
    }
    );

  const [updateAsignacionActividad, { data, loading, error, reset }] = useMutation(UPDATE_ASIGNACIONACTO,
    {
      onError(error){
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `Error! ${error.message}`,
        type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
        autoClose: 5000,  isLoading: false, closeOnClick: true });
        reset();

      },
      onCompleted( data){
        console.log('updateAsignacionActividad', data);
        const id = toast.loading("Por favor espere...")        
        toast.update(id, { render: `AsignacionActividad actualizado`,
        type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
        autoClose: 5000,  isLoading: false, closeOnClick: true });

        history.push({
          pathname:  `/procesos/asignacionactividades`,
          state: {
          response: 'success' 
          } 
        });
        
      }
      
    }
    );

    const [updateAsignacionTarea, { data: dataTarea, loading: loadingTarea,
      error: errorTarea, reset: resetTarea }] = useMutation(UPDATE_ASIGNACIONTAREA,
     {
       awaitRefetchQueries: true,
       onError(error){
         toast.dismiss();
         const id = toast.loading("Por favor espere...")        
         toast.update(id, { render: `Error! ${error.message}`,
         type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
         autoClose: 5000,  isLoading: false, closeOnClick: true });
         reset();
 
       },
       onCompleted(data){
         toast.dismiss();
         const id = toast.loading("Por favor espere...")        
         toast.update(id, { render: `AsignacionTarea actualizado`,
         type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
         autoClose: 5000,  isLoading: false, closeOnClick: true });
         handleRefetchActividades();
         setshowSidebar(null);
         socket.emit('new-message-s', data);     
       }
       
       
     }
     );

     const [makeComentario, { data: dataComentarioTarea, loading: loadingComentarioTarea,
      error: errorComentarioTarea, reset : resetComentarioTarea}] = useMutation(CREATE_COMENTARIO_TAREA,
     {
       awaitRefetchQueries: true,
       onError(error){
         toast.dismiss();
         const id = toast.loading("Por favor espere...")        
         toast.update(id, { render: `Error! ${error.message}`,
         type: "error", position: toast.POSITION.BOTTOM_CENTER, theme: "colored",
         autoClose: 5000,  isLoading: false, closeOnClick: true });
         resetComentarioTarea(); 
       },
       onCompleted(data){
         toast.dismiss();
         const id = toast.loading("Por favor espere...")        
         toast.update(id, { render: `Nuevo comentario`,
         type: "success", position: toast.POSITION.TOP_RIGHT, theme: "dark",
         autoClose: 5000,  isLoading: false, closeOnClick: true });
         setshowSidebar(null);  
         setshowSidebarActividad(null); }
       
       
     }
     );

  let codigo, color, grado, tipo;

  function handleClickedTareaNueva() {
    //console.log(actividad);
    setactionUpdateOrCreate(false);
    setshowSidebar("show");
    setActividadTarea(null)
    setCodigotarea(null);
    setIdTarea(null);
    setGradoTarea(1);
    setColorTarea("#fff");
    setTipoTarea("");
    setEjecutorTarea(null);
    setTareaTarea(null)
    setFechaAsignacionTarea(new Date());
    setFechaEstimadaTarea(new Date());
    setFrecuenciaTarea(5);
    //setComentariosRealizadosTarea(null);
    setAsignoTarea(null);    
    setEstatusTarea(null);
  }

  function shoot(tarea) {
    setactionUpdateOrCreate(true);
    setshowSidebar("show");
    const labelActividad = `${tarea.actividad} - ${tarea.actividadAsignado.actividadAsignado.codigo}[${tarea.actividadAsignado.actividadAsignado.nombre}]`
    setActividadTarea({ value: tarea.actividad, label: labelActividad })
    setCodigotarea(tarea.codigo);
    setIdTarea(tarea.id);
    setGradoTarea(tarea.grado);
    setColorTarea(tarea.color);
    setTipoTarea(tarea.tipo);
    setEjecutorTarea({ value: tarea.ejecutorAsignado.username,
      label: `${tarea.ejecutorAsignado.nombre} ${tarea.ejecutorAsignado.paterno} ${tarea.ejecutorAsignado.materno}` });
    setTareaTarea({ value: tarea.tareaAsignada.codigo, label: tarea.tareaAsignada.nombre })
  
    setFechaAsignacionTarea(new Date(tarea.fechaAsignacion));
    setFechaEstimadaTarea(new Date(tarea.fechaEstimada));
    setFrecuenciaTarea(tarea.frecuencia);
    setComentariosRealizadosTarea(tarea.comentarios.edges);
    setAsignoTarea({ value: tarea.asignadoPor.username,
      label: `${tarea.asignadoPor.nombre} ${tarea.asignadoPor.paterno} ${tarea.asignadoPor.materno}` });
    
    switch (tarea.estatus) {
        case 'P':
          return setEstatusTarea({ value: 'P', label: 'En Proceso' });
        case 'E':
          return setEstatusTarea({ value: 'E', label: 'Pendiente' });
        case 'T':
          return setEstatusTarea({ value: 'T', label: 'Terminada' });
        case 'C':
          return setEstatusTarea({ value: 'C', label: 'Cancelada' });
        default:
          return null;
    }
    //modal.current.open();
    //setModal(true);
  }

  function handleChangeActividad(selectedOption) {  
    setActividad(selectedOption)
  }

  function handleChangeAsesor(selectedOption) {  
    setAsesor(selectedOption)
  }

  function handleChangeActividad(selectedOption) {  
    setActividad(selectedOption)
  }

  function handleChangeAsigno(selectedOption) {  
    setAsigno(selectedOption)
  }

  function handleChangeFechaAsignacion(selectedDate) {  
    setFechaAsignacion(selectedDate)
  }

  function handleChangeFechaEstimada(selectedDate) {  
    setFechaEstimada(selectedDate)
  }

  function handleChangeEstatus(selectedOption) {  
    setEstatus(selectedOption)
  }
  
  function handleSubDesarollo(selectedOption) {  
    setSubDesarollo(selectedOption)
  }

  function handleTipoCategoria(selectedOption) {  
    setTipoCategoria(selectedOption)
  }

  function getColor (estatus)  {
    const today = new Date();
    const fecha = new Date(estatus.fechaEstimada);
  
    //if(fecha.getTime() < today.getTime()){
      //color = 'red';
      //return color;
    //}
    switch (estatus.estatus) {
      case 'P':
        return color = 'green';
      case 'E':
        return  color = 'orange';
      case 'T':
        return  color = 'cyan';
      case 'C':
          return  color = 'brown';
      default:
          return null;
    }
    
  }

  function getEstatus (estatus)  {    
    switch (estatus.estatus) {
      case 'P':
        return color = 'P';
      case 'E':
        return  color = 'E';
      case 'T':
        return  color = 'T';
      case 'C':
          return  color = 'C';
      default:
          return null;
    }
    
  }

  function getEstatusTerminada (estatus)  {    
    switch (estatus.estatus) {
      case 'T':
        return  color = 'line-through';
      default:
          return  color = 'none';
    }
    
  }

  function closeSideBar() {
    setshowSidebar(null);
    setshowSidebarActividad(null);
    //refetch();
  }

  function handleChangeEjecutor(selectedOption) {  
    setEjecutor(selectedOption)
  }
  
  function handleChangeTarea(selectedOption) {  
    setTarea(selectedOption)
  }

  function handleChangeTareaTarea(selectedOption) {  
    setTareaTarea(selectedOption)
  }
  
  function handleChangeAsigno(selectedOption) {  
    setAsigno(selectedOption)
  }
  
  function handleChangeFechaAsignacion(selectedDate) {  
    setFechaAsignacion(selectedDate)
  }
  
  function handleChangeFechaEstimada(selectedDate) {  
    setFechaEstimada(selectedDate)
  }
  
  function handleChangeEstatus(selectedOption) {  
    setEstatus(selectedOption)
  }
  
  function handleChangeComentarios(selectedOption) {  
    setComentarios(selectedOption)
    setComentarioTarea(selectedOption.label)
    console.log(selectedOption);
  }
  
  function handleChangeComentarioTarea(e) {  
    setComentarioTarea(e.target.value)
    console.log(e.target.value);
  }
  
  function handleChangefrecuenciaTarea(e) {  
    setFrecuenciaTarea(e.target.value)
  }

  function handleChangeActividadTarea(selectedOption) {  
    setActividadTarea(selectedOption)
  }
  
  function handleChangeEjecutorTarea(selectedOption) {  
    setEjecutorTarea(selectedOption)
  }

  function handleChangeFechaAsignacionTarea(selectedDate) {  
    setFechaAsignacionTarea(selectedDate)
  }
  
  function handleChangeFechaEstimadaTarea(selectedDate) {  
    setFechaEstimadaTarea(selectedDate)
  }

  function handleChangeEstatusTarea(selectedOption) {  
    setEstatusTarea(selectedOption)
  }

  function handleChangeAsignoTarea(selectedOption) {  
    setAsignoTarea(selectedOption)
  }

  function handleRefetchActividades () {  
    refetch();
  } 

  function handleChangeColorTarea (e) {  
    setColorTarea(e.target.value);
    console.log(e.target.value);
  }

  useEffect(() => {
    const socket = io(ENDPOINT);
      setSocket(socket);
      //socket.emit('new-message-s', 'Hi-flask');
      //console.log('new-message');
      //socket.on("operators", operators => {
       // console.log(operators);
      //});
  
      socket.on('data-tmp', (operators) => {
        console.log(operators, 'data-tmp');
        refetch();        
        });
    if (!showSidebar){
      refetch();
    console.log('useEffect');
    }
      return () => socket.disconnect();
  }, [showSidebar, setSocket, refetch])

  
  
  
  //
  //if (requsitoLoading) return 'Loading...';   
  if (asignacionactividadError) return `Error! ${asignacionactividadError.message}`;
  //if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className="section">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 l6">
                            <h5 className="breadcrumbs-title mt-0 mb-0"><span>Editar AsignacionActividades</span></h5>
                            <ol className="breadcrumbs mb-0">
                                <li className="breadcrumb-item"><a href="index.html">Inicio</a>
                                </li>
                                <li className="breadcrumb-item"><a href="#">Catalogos</a>
                                </li>
                                <li className="breadcrumb-item active">AsignacionActividades
                                </li>
                            </ol>
                        </div>
                        <div className="col s2 m6 l6"><a
                                className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right" href="#!"
                                data-target="dropdown1"><i className="material-icons hide-on-med-and-up">settings</i><span
                                    className="hide-on-small-onl">Settings</span><i
                                    className="material-icons right">arrow_drop_down</i></a>
                            <ul className="dropdown-content" id="dropdown1" tabindex="0">
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="user-profile-page.html">Profile<span className="new badge red">2</span></a>
                                </li>
                                <li tabindex="0"><a className="grey-text text-darken-2"
                                        href="app-contacts.html">Contacts</a></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="page-faq.html">FAQ</a></li>
                                <li className="divider" tabindex="-1"></li>
                                <li tabindex="0"><a className="grey-text text-darken-2" href="user-login.html">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className="row">
                            <div className="col s12 m12 l8">
                                <div id="bordered-table" className="card card card-default scrollspy">
                                <div class="card-content">


          <h4 class="card-title">Catálogo de Asignación de Actividades</h4>
                                                {requsitoLoading ? 
                                                <LoadingComponent /> 
                                                :

          
                                                      <form onSubmit={e => {
                                                              e.preventDefault();
                                                              updateAsignacionActividad({ variables: { asignacionactividadData : {
                                                                 id: asignacionactividadData.viewer.asignacionactividad.id, codigo: codigo.value,
                                                                actividad: selectedActividad ? selectedActividad.value : null,
                                                                subDesarrollo : selectedSubDesarollo ? selectedSubDesarollo.value : null,
                                                                asesor : selectedAsesor ? selectedAsesor.value : null,
                                                                grado : grado.value, color : color.value, tipo: "",
                                                                estatus: selectedEstatus ? selectedEstatus.value : null,
                                                                fechaAsignacion: fechaAsignacion, fechaEstimada: fechaEstimada,
                                                                categoriaId : selectedTipoCategoria ? selectedTipoCategoria.value : null, }} });                                                              
                                                          }}>
                                                          <div class="input-field col s12" style={{display : 'None'}}>
                                                          <i class="material-icons prefix">code</i>
                                                          <input disabled type="text" className="form-control" name="codigo" ref={node => {
                                                              codigo = node; 
                                                          }} defaultValue={asignacionactividadData.viewer.asignacionactividad.codigo} />

                                                          
                                                          <label class="active" for="codigo">Código</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Actividad</p>
                                                            <Select
                                                              value={selectedActividad}
                                                              onChange={handleChangeActividad}
                                                              options={actividadOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Sub Desarrollo</p>
                                                            <Select
                                                              value={selectedSubDesarollo}
                                                              onChange={handleSubDesarollo}
                                                              options={subDesarolloOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Asesor</p>
                                                            <Select
                                                              value={selectedAsesor}
                                                              onChange={handleChangeAsesor}
                                                              options={usuarioOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s6">
                                                            <p>Fecha de Asignación</p>
                                                            <DatePicker
                                                             //showTimeSelect
                                                             //dateFormat="Pp"
                                                             locale="es"
                                                             dateFormat="MMMM d, yyyy"
                                                             selected={fechaAsignacion} onChange={handleChangeFechaAsignacion} />
                                                          </div>

                                                          <div class="input-field col s6">
                                                            <p>Fecha Estimada</p>
                                                            <DatePicker
                                                            locale="es"
                                                            dateFormat="MMMM d, yyyy"
                                                            selected={fechaEstimada} onChange={handleChangeFechaEstimada} />
                                                          </div>                                                       

                                                          

                                                          <div class="input-field col s12">
                                                            <p>Estatus</p>
                                                            <Select
                                                              value={selectedEstatus}
                                                              onChange={handleChangeEstatus}
                                                              options={estatusOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="text" className="form-control" name="frecuencia" ref={node => {
                                                              grado = node;
                                                          }} defaultValue={asignacionactividadData.viewer.asignacionactividad.grado} required/>
                                                          <label class="active" for="grado">Grado</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                          <i class="material-icons prefix">import_contacts</i>
                                                          <input type="color" className="form-control" name="color" ref={node => {
                                                              color = node;
                                                          }} defaultValue={asignacionactividadData.viewer.asignacionactividad.color} required/>
                                                          <label class="active" for="color">Color</label>
                                                          </div>

                                                          <div class="input-field col s12">
                                                            <p>Categoria</p>
                                                            <Select
                                                              value={selectedTipoCategoria}
                                                              onChange={handleTipoCategoria}
                                                              options={tipoCategoriaOptions}
                                                              classNamePrefix="Selecciona una opción"
                                                              isSearchable={true}
                                                            />
                                                          </div>

                                                        

                                                        <div class="row">
                                                            <div class="row">
                                                            <div class="input-field col s12">
                                                            {!loading ? 
                                                              <button class="btn cyan waves-effect waves-light right"
                                                              type="submit" name="action">Enviar
                                                                <i class="material-icons right">send</i>
                                                              </button>
                                                            
                                                              :

                                                              <button class="btn btn-customizer cyan waves-effect waves-light right"
                                                              type="submit" name="action">Procesando
                                                                <i class="material-icons right">sync</i>
                                                              </button>
                                                            
                                                            }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                      }
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    </div>
                                </div>
                                </div>

                                <div className="col s12 m12 l4">
                                                {requsitoLoading ? 
                                                <LoadingComponent /> 
                                                :

          
                                                <ul id="task-card" class="collection with-header animate fadeLeft">
                                                <li class="collection-header orange">
                                                   <h5 class="task-card-title mb-3">Lista de Tareas</h5>
                                                   
                                                </li>

                                                <ul class="collection mb-0">
                                                {
                                                asignacionactividadData.viewer.asignacionactividad.asignacionTareas.edges.map(tarea =>
                                                <li class="collection-item avatar" key={tarea.node.id} onClick= {(e) =>shoot(tarea.node)} 
                                                style={{cursor: 'pointer'}}>
                                                  <img className="circle" onClick={shoot} title={`${tarea.node.ejecutorAsignado.nombre} ${tarea.node.ejecutorAsignado.paterno} ${tarea.node.ejecutorAsignado.materno}`}
                                                    src={
                                                      createImageFromInitials(100, `${tarea.node.ejecutorAsignado.nombre} ${tarea.node.ejecutorAsignado.paterno}`, getRandomColor())
                                                    } alt="Avatar"/>
                                                  <p class="font-weight-600" style={{textDecoration: getEstatusTerminada(tarea.node) }}>{tarea.node.tareaAsignada.nombre}</p>
                                                  <p class="medium-small">{Moment(tarea.node.fechaEstimada).fromNow(new Date(tarea.node.fechaEstimada) > new Date() ? true : false)}, {Moment(tarea.node.fechaEstimada).format('dddd, D [de] MMMM [de] YYYY')}</p>
                                                  <a href="#!" class="secondary-content"><span className={`task-cat ${getColor(tarea.node)}`}>{getEstatus(tarea.node)}</span></a>
                                                </li>
                                                )}
                  
                                                </ul>

                                                
                                             </ul>
                                                      }
                                                     
                                                      {error && <p>Error :( {error.message}</p>}
                                                      

                                                     
                                                     
                                                    
                                </div>
                    </div>

                    <div className={`kanban-sidebar ps ps--active-y ${showSidebar} ps--scrolling-y`}>
  <Scrollbar style={{ width: 100 + '%', height: 100 + '%' }}>
    <div className="card quill-wrapper">
      <div className="card-content pt-0">
        <div className="card-header display-flex pb-2">
          <h3 className="card-title">Asignación de Tareas</h3>
          <div className="close close-icon" onClick={closeSideBar}>
            <i className="material-icons">close</i>
          </div>
        </div>
        <div className="divider"></div>
        <form className="edit-kanban-item mt-10 mb-10" 
        onSubmit={e => {
          e.preventDefault();
          
          if(actionUpdateOrCreate){
          updateAsignacionTarea({ variables: { asignaciontareaData : { 
            id: idTarea,
            codigo: codigoTarea,
            actividad: codigo.value,
            ejecutor: selectedEjecutorTarea ? selectedEjecutorTarea.value : null,
            tarea: selectedTareaTarea ? selectedTareaTarea.value : null,
            fechaAsignacion: fechaAsignacionTarea, fechaEstimada: fechaEstimadaTarea,
            frecuencia: frecuenciaTarea, asigno: selectedAsignoTarea ? selectedAsignoTarea.value : null,
            grado : gradoTarea, color : colorTarea, tipo: tipoTarea,
            estatus: selectedEstatusTarea ? selectedEstatusTarea.value : null }} });
          }else{

            createAsignacionTarea({ variables: { asignaciontareaData : {
              codigo: codigo.value,
              actividad: codigo.value,
              ejecutor: selectedEjecutorTarea ? selectedEjecutorTarea.value : null,
              tarea: selectedTareaTarea ? selectedTareaTarea.value : null,
              fechaAsignacion: fechaAsignacionTarea, fechaEstimada: fechaEstimadaTarea,
              frecuencia: frecuenciaTarea, asigno: selectedAsignoTarea ? selectedAsignoTarea.value : null,
              grado : gradoTarea, color : colorTarea, tipo: tipoTarea,
              estatus: selectedEstatusTarea ? selectedEstatusTarea.value : null }} });

          }  



          
      }}>

          {/*
          <div className="input-field">
            <input type="text" className="edit-kanban-item validate" 
            id="edit-item-ejecutor" placeholder="Ejemplo pedrogarcia"
            name="codigo" defaultValue={codigoTarea}/>
            <label for="edit-item-title">Codigo</label>
          </div>
          <div className="input-field">
                  <p>Actividad</p>
                  <Select
                    value={selectedActividadTarea}
                    onChange={handleChangeActividad}
                    options={[]}
                    classNamePrefix="Selecciona una opción"
                    isSearchable={true}
                  />            
          </div>
          */}
          

          <div className="input-field">
                  <p>Ejecutor</p>
                    <Select
                      value={selectedEjecutorTarea}
                      onChange={handleChangeEjecutorTarea}
                      options={usuarioOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />          
          </div>

          
          <div className="input-field">
                  <p>Tarea</p>
                    <Select
                      value={selectedTareaTarea}
                      onChange={handleChangeTareaTarea}
                      options={tareaOptionsTarea}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />          
          </div>

          <div className="input-field">
            <p>Fecha de Asignación</p>
            <DatePicker
            //showTimeSelect
            //dateFormat="Pp"
            locale="es"
            dateFormat="MMMM d, yyyy"
            selected={fechaAsignacionTarea} onChange={handleChangeFechaAsignacionTarea} />
          </div>
          <div className="input-field">
          <p>Fecha Estimada</p>
            <DatePicker
            //showTimeSelect
            //dateFormat="Pp"
            locale="es"
            dateFormat="MMMM d, yyyy"
            selected={fechaEstimadaTarea} onChange={handleChangeFechaEstimadaTarea} />
          </div>
          <div className="input-field">
            <input type="text" className="edit-kanban-item validate" 
            id="edit-item-recordatorio" defaultValue={frecuenciaTarea}
            onChange={handleChangefrecuenciaTarea} placeholder="Ejemplo 5 horas"/>
            <label for="edit-item-title">Frecuencia Recordatorio</label>
          </div>
          <div className="input-field">
                    <p>Asigno</p>
                    <Select
                      //isMulti
                      name="actividades"
                      value={selectedAsignoTarea}
                      onChange={handleChangeAsignoTarea}
                      options={usuarioOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />
          </div>

          <div className="input-field">
                    <p>Estatus</p>
                    <Select
                      value={selectedEstatusTarea}
                      onChange={handleChangeEstatusTarea}
                      options={estatusOptionsTarea}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />
          </div>

          <div className="input-field">
          <p>Color</p>
          <input type="color" className="edit-kanban-item validate" name="color" value={colorTarea}
                onChange={handleChangeColorTarea}  required />
          </div>


          { comentariosRealizadosTarea.length > 0 && actionUpdateOrCreate ?
          <>
          <p>Comentarios</p>
          <div class="sidebar-content sidebar-chat ps ps--active-y" style={{height : 200+'px'}}>
                    <Scrollbar style={{ width: 100 + '%', height: 100 + '%' }}>
                        <div class="chat-list">

                        {
                          comentariosRealizadosTarea.map(
                            record =>
                          <div class="chat-user animate fadeUp delay-3">
                            <div class="user-section">
                              <div class="row valign-wrapper">
                                <div class="col s2 media-image away pr-0">
                                  <img 
                                  src={
                                    createImageFromInitials(100, `${record.node.comentarioPor.nombre} ${record.node.comentarioPor.paterno}`, getRandomColor())
                                 }
                                  alt="" class="circle z-depth-2 responsive-img"/>
                                </div>
                                <div class="col s10">
                                  <p class="m-0 blue-grey-text text-darken-4 font-weight-700">
                                  {record.node.comentarioPor.nombre + ' '+ record.node.comentarioPor.paterno + ' ' + record.node.comentarioPor.materno}
                                  </p>
                                  <p class="m-0 info-text">{record.node.comentario}</p>
                                </div>
                              </div>
                            </div>
                            <div class="info-section">
                              <div class="star-timing">
                                <div class="time">
                                  <span>{Moment(record.node.createdAt).format('LLL')} hrs.</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          )
                          }
                        </div>
                        </Scrollbar>
          </div>
          
          </>
          :
          actionUpdateOrCreate &&
          <p>Sin Comentarios...</p>
          
          }

          

          <div className="card-action pl-0 pr-0">
          <button type="reset" className="btn-small delete-kanban-item mr-1">
            <span>Eliminar</span>
          </button>
          {!loadingTarea && !createAsignacionTareaLoading ?
          <button type="submit" className="btn-small blue update-kanban-item">
            <span>Guardar</span>
          </button>
          : 
          <button className="btn-small btn-customizer blue update-kanban-item">
            <span>Procesando
           <i class="material-icons right">sync</i></span>
          </button>
          }
          </div>
        </form>

        {actionUpdateOrCreate &&
        <form className="edit-kanban-item mt-10 mb-10" 
                  onSubmit={e => {
                    e.preventDefault();
                    makeComentario({ variables: { asignacioncomentarioData : { 
                      id: idTarea,
                      tareaComentarioCodigo: codigoTarea,
                      actividadComentarioCodigo: null,
                      comentario: comentarioTarea }} });
                    
                }}>
          <div className="input-field">
                  <p>Comentarios prediseñados</p>
                    <Select
                      value={selectedComentarios}
                      //isMulti
                      styles={customStyles}
                      name="comentarios"
                      onChange={handleChangeComentarios}
                      options={comentariosOptions}
                      classNamePrefix="Selecciona una opción"
                      isSearchable={true}
                    />          
          </div>

          <div className="input-field">
          <p>Nuevo Comentario</p>
            <textarea id="textarea2" value={comentarioTarea} onChange={handleChangeComentarioTarea} 
            class="materialize-textarea" required/>
            {!loadingComentarioTarea ?
              <button type="submit" class="btn waves-effect waves-light send">Comentar</button>
            : 
            <button class="btn btn-customizer waves-effect waves-light send">
              <span>Comentando
           <i class="material-icons right">sync</i></span></button>
            }
          </div>

        </form>
        }
        
      </div>
    </div>
    <div class="ps__rail-x" style={{left: 0, bottom: -910}}>
      <div class="ps__thumb-x" tabindex="0" style={{left: 0, width: 0}}></div>
    </div>
    <div class="ps__rail-y" style={{top: 910, height: 349, right: 0}}>
      <div class="ps__thumb-y" tabindex="0" style={{top: 253, height: 96}}></div>
    </div>
    </Scrollbar>
                    </div>

  <div style={{bottom: 30+ 'px', right: 19+'px'}} class="fixed-action-btn direction-top">
    <a onClick= {(e) =>handleClickedTareaNueva()} class="btn-floating btn-large gradient-45deg-light-blue-cyan gradient-shadow">
      <i class="material-icons">add</i></a>
</div>

                    
      </div>


  );
}

AsignacionActividadesEditComponent.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

export default AsignacionActividadesEditComponent;
